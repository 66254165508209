<script setup lang="ts">
import type { ParsedContent } from '@nuxt/content'
import { joinParts, parenthesize, titleCase } from './util'

const props = defineProps<{
  item: ParsedContent
}>()

const text = computed(() => {
  const size = props.item.size ? titleCase(props.item.size) : null
  const type = props.item.type ? titleCase(props.item.type) : null
  const cr = props.item.cr ? `CR ${props.item.cr}` : null
  const role = props.item.role ? titleCase(props.item.role) : null

  const paren = parenthesize(joinParts(' ', cr, role))

  return joinParts(' ', size, type, paren)
})
</script>

<template>
  <p class="subtitle subtitle--creature">
    {{ text }}
  </p>
</template>

<style lang="postcss" scoped></style>
