<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="aoes"
    :width="dimensions.x * GRID_SIZE + 'px'"
    :height="dimensions.y * GRID_SIZE + 'px'"
  >
    <SVGArea v-for="(area, id) in props.areas" :key="id" :area="area" />
    <SVGArea v-if="props.tempArea" :key="props.tempArea.id" :area="props.tempArea" />
  </svg>
</template>
<script setup lang="ts">
import SVGArea from './SVGArea.vue'
import { type AreaData, GRID_SIZE } from './tabletopUtil'

const props = defineProps<{
  areas: Record<string, AreaData>
  tempArea: AreaData | null
}>()

const { dimensions } = useTabletop()
</script>

<style lang="postcss">
.aoes {
  position: absolute;
  z-index: 175;
  pointer-events: none;
}
</style>
