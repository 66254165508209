<script setup lang="ts">
const { page } = useContent()
</script>

<template>
  <NuxtLayout>
    <article v-if="page">
      <PageTitle />
      <PageSubtitle :item="page" />
      <ContentDoc class="nuxt-content" />
      <PageFooter />
    </article>
  </NuxtLayout>
</template>
