<script setup lang="ts">
import Fuse from 'fuse.js'
import { clamp, flattenNavigation, slugify, titleCase } from './util'

const { navigation } = useContent()

const allPages = flattenNavigation(navigation.value)
const goToRandomArticle = () => {
  const index = Math.floor(allPages.length * Math.random())
  const page = allPages[index]
  navigateTo(page._path)
}

type ResultPage = {
  _path: string
  title: string
  tag?: string
}

const pages = computed(() => allPages.filter((p) => p.public || dm.value))

const dm = useDM()
const searcher = computed(() => {
  return new Fuse(pages.value, {
    keys: [{ name: 'searchTitle', weight: 2 }, { name: 'searchTags' }],
    includeMatches: true,
    includeScore: true,
    isCaseSensitive: false,
  })
})

const searchTerm = ref('')
const searchResults = computed((): ResultPage[] => {
  const needle = slugify(searchTerm.value)
  if (!needle) return []
  const res = searcher.value.search(needle, { limit: 5 })

  return res
    .filter((r) => r.score! < 0.25)
    .map((r) => {
      const match = r.matches![0] // the most influential match
      const res: ResultPage = {
        _path: r.item._path,
        title: r.item.title,
      }

      // resolve anchor from tag matches
      if (match.key === 'searchTags') {
        const tag = match.value!

        const frag = r.item.tags?.[match.value!]
        if (!frag) {
          // just an alias, go to top of target page
        } else if (frag === '§') {
          res._path += '#' + slugify(tag)
          res.tag = tag.replaceAll('-', ' ')
        } else {
          res._path += '#' + slugify(frag)
          res.tag = frag
        }
      }

      return res
    })
})

const index = ref(0)
const goToFirstResult = () => {
  const firstResult = searchResults.value[index.value]
  navigateTo(firstResult._path)
}

watch(searchResults, () => {
  index.value = 0
})

const search = ref(null as HTMLElement | null)
const checkBlur = (ev: KeyboardEvent) => {
  if (ev.key === 'Escape') {
    search.value?.blur()
    searchTerm.value = ''
    ev.preventDefault()
  }
  if (ev.key === 'ArrowDown') {
    index.value = clamp(index.value + 1, 0, searchResults.value.length - 1)
  }
  if (ev.key === 'ArrowUp') {
    index.value = clamp(index.value - 1, 0, searchResults.value.length - 1)
  }
}

onBodyEvent('keypress', (event: Event) => {
  const ev = event as KeyboardEvent
  if (ev.target === document.body && ev.key === 's' && search.value) {
    ev.preventDefault()
    search.value?.focus()
  }
})
</script>

<template>
  <div class="search-bar" :class="{ 'has-results': searchResults.length > 0 }">
    <div class="search">
      <form class="search-form" @submit.prevent="goToFirstResult">
        <input
          ref="search"
          v-model="searchTerm"
          type="text"
          class="search-input title-font"
          placeholder="Search..."
          @keydown="checkBlur"
        />
      </form>
      <button :title="`Random Article (${pages.length} total)`" class="random-page" @click="goToRandomArticle">
        <Icon name="estia:d20" class="size-1hx" />
      </button>
    </div>
    <div v-if="searchResults.length > 0" class="search-results">
      <div v-for="(result, i) in searchResults" :key="result.title" class="result" :class="{ highlight: index === i }">
        <NuxtLink
          :to="result._path"
          class="result-link"
          :active-class="undefined"
          :exact-active-class="undefined"
          no-prefetch
          @click="searchTerm = ''"
          >{{ result.title }} <span v-if="result.tag" class="tag"># {{ titleCase(result.tag) }}</span></NuxtLink
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.search-bar {
  margin-top: 1rem;
  position: relative;

  .search {
    display: flex;
    align-items: stretch;

    button,
    .search-input {
      border: 2px solid var(--primary);
      border-width: 2px 1px;
      margin: 0;
      padding: 0.5rem 1rem;
      border-radius: 0;

      background: var(--background);
      color: var(--text-highlight);
      font-weight: 500;
      line-height: 1.4;
    }

    .search-input {
      flex: 1;
      padding: 0.5rem 1.5rem;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      border-left-width: 2px;
      width: 100%;
      outline: none;
      box-shadow: var(--card-shadow);
    }

    .random-page {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
      border-right-width: 2px;
      padding: 0 0.5em;

      display: flex;
      align-items: center;
    }
  }

  &.has-results {
    .search-form {
      .search-input {
        border-bottom-left-radius: 0;
      }

      .random-page {
        border-bottom-right-radius: 0;
      }
    }
  }

  .search-results {
    position: absolute;
    background: var(--background);

    top: 100%;
    left: 0;
    right: 0;
    z-index: 100;

    .result {
      padding: 0.5rem 1.5rem;
      border-left: 2px solid var(--primary);
      border-right: 2px solid var(--primary);
      border-bottom: 2px solid var(--primary);

      &.highlight {
        background: var(--bg-primary);
      }

      &:last-of-type {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }

      a.router-link-active,
      a.router-link-exact-active {
        color: var(--primary) !important;
      }

      .tag {
        font-size: 0.9em;
      }
    }
  }
}
</style>
