<script setup lang="ts">
import type { ParsedContent } from '@nuxt/content'
import { joinParts, parenthesize, titleCase } from './util'
import { ageText, type CharacterData } from './familytree/familytree'

const props = defineProps<{
  item: CharacterData | ParsedContent
  condensed?: boolean
}>()

const char = props.item as CharacterData

const subtitle = computed(() => {
  const age = ageText(char)
  if (!props.condensed) {
    return joinParts(
      ' ',
      titleCase(char.subrace),
      titleCase(char.race),
      titleCase(char.subclass),
      titleCase(char.class),
      parenthesize(joinParts(', ', titleCase(char.background), char.alignment?.toUpperCase(), age))
    )
  } else {
    const core = joinParts(' ', char.subrace?.toLowerCase(), char.race?.toLowerCase(), char.class?.toLowerCase())
    return joinParts(', ', core, char.alignment?.toUpperCase(), age)
  }
})
</script>

<template>
  <p class="subtitle subtitle--character">{{ subtitle }}</p>
</template>

<style scoped lang="postcss"></style>
