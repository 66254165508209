// this file and estia-server/src/model/model.ts should be kept in sync

import _ from 'lodash'
import { clamp, minmax } from '../util'

export const GRID_SIZE = 60

export type Position = {
  x: number
  y: number
}

export type Area = Position & {
  w: number
  h: number
}

export type CharData = {
  id: string
  name: string
  number?: number
  sprite: string
  position: Position
  size: number | string
  aura: number
  friendly: boolean
  initiative?: number
  initMod?: number
  conditions: string[]
  mountedOn: string | null
}

export type PlayerData = {
  id: string
  name: string
  color: string
  pointers: Area[]
  dm: boolean
}

export type FogData = {
  id: string
  rect: Area & { type: string }
}

export type AreaType = 'sphere' | 'line' | 'cube' | 'cone'
export const AREA_TYPES: AreaType[] = ['sphere', 'line', 'cube', 'cone']
export type AreaData = {
  id?: string
  owner?: string // player uuid
  origin: Position
  target: Position
  type: AreaType
}

export type MapData = {
  sprite: string
  style: Record<string, string>
  dimensions: Position
  fog: Record<string, FogData>
  aoes: Record<string, AreaData>
}

export type TabletopData = {
  characters: Record<string, CharData>
  players: Record<string, PlayerData>
  map: MapData
}

export type DropFn = (pos: Position, target: HTMLElement) => void
export type DropHandler = {
  end: DropFn
  progress?: DropFn
  dropClass?: string
  lastProgress?: Position | null
}

export const computePosition = (table: TabletopData, char: CharData): Vec2 => {
  let x = char.position.x
  let y = char.position.y

  if (char.mountedOn) {
    const mount = Object.values(table.characters).find(({ id }) => char.mountedOn === id)
    if (mount) {
      x += mount.position.x - 1
      y += mount.position.y - 1
    }
  }

  return [clamp(x, 0, table.map.dimensions.x), clamp(y, 0, table.map.dimensions.y)]
}

export const contains = ({ x, y, w, h }: Area, { x: px, y: py }: Position) =>
  x <= px && px < x + w && y <= py && py < y + h

export const createArea = (pos1: Position, pos2: Position): Area => ({
  x: Math.min(pos1.x, pos2.x),
  y: Math.min(pos1.y, pos2.y),
  w: Math.abs(pos1.x - pos2.x) + 1,
  h: Math.abs(pos1.y - pos2.y) + 1,
})

export const gridDistance = ([x1, y1]: Vec2, [x2, y2]: Vec2, round = true): number => {
  const xdiff = Math.abs(x1 - x2)
  const ydiff = Math.abs(y1 - y2)

  const diag = Math.min(xdiff, ydiff)
  const diagCost = diag * 1.5

  const res = xdiff + ydiff - 2 * diag + diagCost
  if (round) return Math.floor(res)
  return res
}

export const toPoints = (ps: Vec2[]): string => ps.map((p) => p.map((n) => n * GRID_SIZE).join(' ')).join(', ')

export const createRectangle = (origin: Position, target: Position): Vec2[] => {
  const o = [origin.x, origin.y]
  const t = [target.x, target.y]

  const corners = allCorners([o, t])

  const [minX, maxX] = minmax(corners, ([x]) => x)
  const [minY, maxY] = minmax(corners, ([, y]) => y)

  const rectangle: Vec2[] = [
    [minX, minY],
    [maxX, minY],
    [maxX, maxY],
    [minX, maxY],
  ]

  return rectangle
}

export const createSphere = (origin: Position, target: Position): Vec2[] => {
  const r = gridDistance([origin.x, origin.y], [target.x, target.y])

  const xs = _.range(Math.floor(origin.x - r), origin.x + r + 1)
  const ys = _.range(Math.floor(origin.y - r), origin.y + r + 1)

  const squares = xs.flatMap((x) => ys.map((y) => [x, y]))

  const mid = [origin.x, origin.y]
  const cands = squares.filter(([x, y]) => {
    const dist = gridDistance([x, y], mid, false)
    return dist - r <= 1
  })

  const polygon = gridHull(cands)
  return polygon
}

export const createLine = (origin: Position, target: Position): Vec2[] => {
  const o = [origin.x, origin.y]
  const [x1, y1] = o
  const t = [target.x, target.y]
  const [x2, y2] = t
  const length = gridDistance(o, t)

  const [xmin, xmax]: number[] = minmax([x1, x2])
  const [ymin, ymax]: number[] = minmax([y1, y2])

  const xs = _.range(xmin, xmax + 1)
  const ys = _.range(ymin, ymax + 1)
  const squares = xs.flatMap((x) => ys.map((y) => [x, y]))

  const cands = squares.filter((p) => {
    const [x0, y0] = p
    const distFromLine = Math.abs((y2 - y1) * x0 - (x2 - x1) * y0 + x2 * y1 - y2 * x1) / length
    const distFromOrigin = gridDistance(o, p)
    return distFromLine < 0.5 && distFromOrigin <= length
  })

  const corners = allCorners(cands)

  return gridHull(corners)
}

export const createCone = (origin: Position, target: Position): Vec2[] => {
  const mid = [origin.x, origin.y]
  const tar = [target.x, target.y]

  const r = gridDistance([origin.x, origin.y], [target.x, target.y])

  const xs = _.range(Math.floor(origin.x - r), origin.x + r + 1)
  const ys = _.range(Math.floor(origin.y - r), origin.y + r + 1)

  const squares = xs.flatMap((x) => ys.map((y) => [x, y]))

  const angleLimit = Math.PI / 4
  const circle = Math.PI + Math.PI
  const refAngle = theta(sub(tar, mid))
  const cands = squares.filter((pos) => {
    const dist = gridDistance(pos, mid, false)
    const angle = theta(sub(pos, mid))
    const ang = angle - refAngle
    const angleCorrect = Math.abs(ang) <= angleLimit || circle - Math.abs(ang) <= angleLimit
    return dist === 0 || (dist - 1 < r && angleCorrect)
  })

  const corners = allCorners(cands)
  const polygon = gridHull(corners)
  return polygon
}

// converts from grid-coords to world-coords
const allCorners = (points: Vec2[]): Vec2[] =>
  _.uniqBy(
    points.flatMap(([x, y]) => [
      [x, y],
      [x + 1, y],
      [x + 1, y + 1],
      [x, y + 1],
    ]),
    ([x, y]) => 1000 * x + y
  )

const gridHull = (sqs: Vec2[]): Vec2[] => {
  const [minX, maxX] = minmax(sqs, ([x]) => x)

  const upper = []
  const lower = []

  for (const x of _.range(minX, maxX + 1)) {
    const col = sqs.filter(([px, _py]) => px === x)
    const minY = _.minBy(col, ([_x, y]) => y)![1]
    const maxY = _.maxBy(col, ([_x, y]) => y)![1]

    const p1 = [x, minY]
    const p2 = [x, maxY]

    upper.push(p1)
    lower.push(p2)
  }

  const hull = upper.concat(lower.toReversed())

  return ensureGrid(hull)
}

const ensureGrid = (hull: Vec2[]): Vec2[] => {
  // cross product vectors between three sequential points
  // 0 if colinear, positive if left turn, negative if right turn
  const angle = ([x1, y1]: Vec2, [x2, y2]: Vec2, [x3, y3]: Vec2): number => {
    return (x2 - x1) * (y3 - y1) - (y2 - y1) * (x3 - x1)
  }

  const corner = (p1: Vec2, p2: Vec2): Vec2 => {
    const [x1, y1] = p1
    const [x2, y2] = p2
    const c1 = [x1, y2]
    const c2 = [x2, y1]
    const a1 = angle(p1, c1, p2)
    const a2 = angle(p1, c2, p2)

    if (a1 <= a2) return c1
    return c2
  }

  return hull.flatMap((p, index) => {
    const prev = hull[index - 1] ?? hull[hull.length - 1] // wrap around
    if (!isOrtholinear(prev, p)) {
      return [corner(prev, p), p]
    }
    return [p]
  })
}

type Vec2 = number[]

const sub = ([x1, y1]: Vec2, [x2, y2]: Vec2): Vec2 => [x1 - x2, y1 - y2]
// const dot = ([x1, y1]: Vec2, [x2, y2]: Vec2): number => x1 * x2 + y1 * y2
1
// const len2 = (x: Vec2): number => dot(x, x)
const theta = ([x1, y1]: Vec2): number => Math.atan2(y1, x1)

const isOrtholinear = ([x1, y1]: Vec2, [x2, y2]: Vec2): boolean => x1 === x2 || y1 === y2
