<template>
  <figure class="wiki-image" :style="{ 'max-width': width ? `${width}px` : 'auto' }">
    <img :src="props.src" :alt="alt || ''" v-bind="$attrs" loading="lazy" />
    <figcaption>
      <slot></slot>
    </figcaption>
  </figure>
</template>

<script setup lang="ts">
const props = defineProps<{
  src: string
  alt?: string
  width?: number
}>()
</script>

<style lang="postcss">
.wiki-image {
  margin: 1.5em auto;
  padding: 0;
  overflow: hidden;

  img {
    max-width: 100%;
  }

  @media (min-width: 950px) {
    margin: 0 0 1em;

    &.floatright {
      margin-left: 1em;
      max-width: 250px;
    }

    &.floatleft {
      margin-right: 1em;
      max-width: 250px;
    }
  }

  &.lineart {
    background: #f8f8f8;
    border-radius: 0.5em;
    padding: 0.5rem;

    figcaption {
      color: black;
      font-size: 0.8em;
      font-family: var(--font-stack-title);
    }
  }
}

header + .wiki-image {
  &.floatright:first-child,
  &.floatleft:first-child {
    margin-top: 0.5em;
  }
}

.wiki-image figcaption {
  text-align: center;
  padding-top: 0.25rem;
  font-size: 0.9em;
  font-weight: 500;

  p:last-child {
    margin-block-end: 0;
  }
}
</style>
