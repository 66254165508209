<template>
  <span>{{ enabled ? tween : actual }}</span>
</template>

<script setup lang="ts">
const props = defineProps<{
  actual: number
  maximum?: number
  enabled?: boolean
}>()

const animating = ref(0)
const handle = ref<any>(0)
const tween = ref(0)

watch(props, (val, old) => {
  if (val.actual === old.actual) return
  startAnimation()
})

onMounted(() => startAnimation())

const startAnimation = () => {
  if (!props.enabled) return
  animating.value = 1000
  clearInterval(handle.value)
  handle.value = setInterval(() => {
    animating.value = animating.value - 100
    tween.value = Math.ceil((animating.value * Math.random()) % (props.maximum || 9))
    if (animating.value <= 0) {
      clearInterval(handle.value)
      tween.value = props.actual
    }
  }, 100)
}
</script>

<style scoped lang="postcss"></style>
