<template>
  <canvas
    ref="canvas"
    class="overlay"
    :width="width * GRID_SIZE + 'px'"
    :height="height * GRID_SIZE + 'px'"
    @mousemove="updateGrid"
  ></canvas>
</template>

<script setup lang="ts">
import _ from 'lodash'
import { numberify } from '../util'
import { computePosition, GRID_SIZE, gridDistance } from './tabletopUtil'

const canvas = ref<HTMLCanvasElement>()

const { table } = useTabletop()

const props = defineProps<{
  width: number
  height: number
  target: string | null
}>()

const ctx = ref<CanvasRenderingContext2D | null>(null)
const gridX = ref(0)
watch(gridX, (x, old) => {
  if (x === old) return
  drawLine()
})

const gridY = ref(0)
watch(gridY, (y, old) => {
  if (y === old) return
  drawLine()
})

onMounted(() => {
  ctx.value = canvas.value?.getContext('2d') || null
})

const updateGrid = (ev: MouseEvent) => {
  // possibly throttle this to every 250ms or so
  gridX.value = Math.floor(ev.layerX / GRID_SIZE)
  gridY.value = Math.floor(ev.layerY / GRID_SIZE)
}

const player = computed(() => {
  if (!props.target) return null
  return table.value.characters[props.target]
})

watch(player, (p) => {
  if (p === null) {
    clear()
  } else {
    drawLine()
  }
})

const origin = computed(() => {
  // no need for sqrt if we just want to compare the distances
  const euclideanSquared = ([x, y]: number[]) => {
    const dx = x - gridX.value
    const dy = y - gridY.value
    return dx * dx + dy * dy
  }

  // we find the closest square to the target.
  // We only really have to check the edges, as the middle can never be closer.
  // This only matters for huge and larger creatures, and the center never makes up more than half the candidate squares
  // const xCands2 = [this.player.position.x - 1, ...(this.player.size > 1 ? [this.player.position.x - 1 + parseInt(this.player.size)] : [])]
  const char = player.value!
  const [cx, cy] = computePosition(table.value, char)
  const xCands = _.range(cx - 1, cx - 1 + numberify(char.size, 1))
  const yCands = _.range(cy - 1, cy - 1 + numberify(char.size, 1))

  const gridSquares = xCands.flatMap((x) => yCands.map((y) => [x, y]))
  const sortedByDistance = gridSquares.sort((grid1, grid2) => euclideanSquared(grid1) - euclideanSquared(grid2))
  const [x, y] = sortedByDistance[0]

  return centerOf(x, y)
})

const destination = computed(() => centerOf(gridX.value, gridY.value))

const centerOf = (x: number, y: number, size = 1) => {
  const offset = (size * GRID_SIZE) / 2
  return [x * GRID_SIZE + offset, y * GRID_SIZE + offset]
}

const distance = computed(() => {
  const [x1, y1] = origin.value
  const [x2, y2] = destination.value

  // we need grid units here to ensure the diag cost operation can be performed with floor
  const dist = gridDistance([x1 / GRID_SIZE, y1 / GRID_SIZE], [x2 / GRID_SIZE, y2 / GRID_SIZE])

  return `${5 * dist}`
})

const clear = () => ctx.value?.clearRect(0, 0, props.width * GRID_SIZE, props.height * GRID_SIZE)

const drawLine = () => {
  if (!player.value) return
  if (!ctx.value) return
  clear()

  ctx.value.strokeStyle = '#1F64E5'
  ctx.value.font = '30px Figuera'
  ctx.value.textAlign = 'center'
  ctx.value.textBaseline = 'middle'
  ctx.value.fillStyle = '#fff'
  ctx.value.lineWidth = 5

  const [destX, destY]: number[] = destination.value
  const [originX, originY]: number[] = origin.value
  ctx.value.beginPath()
  ctx.value.moveTo(originX, originY)
  ctx.value.lineTo(destX, destY)
  ctx.value.stroke()

  ctx.value.strokeStyle = '#000'
  ctx.value.lineWidth = 2
  ctx.value.strokeText(distance.value, destX, destY)
  ctx.value.fillText(distance.value, destX, destY)
}
</script>

<style scoped lang="postcss">
canvas {
  position: absolute;
  z-index: 150;
}
</style>
