<script setup lang="ts">
import { resolveMods, type BaseStats } from '../statblock/statblock'
import DiceResult from './DiceResult.vue'
import {
  evaluate,
  isCriticalCheck,
  parseDice,
  type DiceContext,
  type DiceExpr,
  type ExtraMod,
  type MultiDiceResult,
} from '~/assets/dice/diceEval'

const dice = ref('')
const allResults = ref<MultiDiceResult[]>([])

const filteredRolls = computed(() => allResults.value.slice(0, 5).filter((r) => !r.hidden))

const store = useStore()
const broadcasting = computed(() => store.playerData.broadcastDice)
const playerName = computed(() => store.playerData.name)
const broadcastDice = async (res: MultiDiceResult) => {
  if (!broadcasting.value) return
  await fetch('https://estia-server-m6x2fqeyga-ew.a.run.app/dice', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(res),
  })
}

const open = ref(false)
const input = ref<HTMLElement | null>(null)
const openModal = () => {
  open.value = true
  setTimeout(() => input.value?.focus())
}
const close = () => (open.value = false)
const checkClose = (ev: KeyboardEvent) => {
  if (ev.key === 'Escape') {
    ev.preventDefault()
    close()
  } else if (ev.ctrlKey && ev.key === 'l') {
    ev.preventDefault()
    dice.value = ''
    allResults.value = []
  }
}

const roll = (dice: string, variables: Record<string, DiceExpr> = {}, label: string | null = null) => {
  if (!dice) return

  const extraMods: ExtraMod[] = []
  const results = dice
    .toLowerCase()
    .split(',')
    .map((xpr, index) => {
      const ctx: DiceContext = {
        variables,
        globalMods: [],
        extraMods,
      }
      const expr = parseDice(xpr, ctx)!
      const result = evaluate(expr, ctx)
      if (index === 0 && isCriticalCheck(result)) {
        // if the first dieroll is a crit, roll remaining dice as crits
        extraMods.push({ type: 'c' })
      }

      return result
    })
    .filter((res) => !!res)

  const res: MultiDiceResult = {
    results,
    player: playerName.value,
    id: Math.floor(10000000 * Math.random()),
    hidden: false,
    label,
  }

  appendResults(res)

  broadcastDice(res)

  return true
}

const appendResults = (res: MultiDiceResult) => {
  if (allResults.value.some((r) => r.id === res.id)) return
  allResults.value = [res, ...allResults.value]
  if (allResults.value.length > 20) {
    allResults.value = allResults.value.slice(0, 20)
  }
}

const clearRoll = (res: MultiDiceResult) => {
  const roll = allResults.value.find((r) => r.id === res.id)
  if (roll) roll.hidden = true
}

const { page } = useContent()
const submit = () => {
  const vars = resolveMods(page as unknown as BaseStats)
  roll(dice.value, vars)
}

onBodyEvent('diceResult', (ev: Event) => {
  const { dice } = (ev as CustomEvent).detail
  const results = dice as MultiDiceResult
  appendResults(results)
})

onBodyEvent('rollDice', (ev: Event) => {
  const { dice, variables, label } = (ev as CustomEvent).detail
  roll(dice, variables, label)
})

onBodyEvent('keypress', (ev: KeyboardEvent) => {
  if (ev.target === document.body && ev.key === 'r') {
    ev.preventDefault()
    openModal()
  }
})
</script>

<template>
  <client-only>
    <div v-if="open" class="global-dice-modal">
      <form class="dice-form" @submit.prevent="submit">
        <input
          ref="input"
          v-model="dice"
          type="text"
          class="dice-input title-font"
          placeholder="8d6, 4d6h3, d20a + 5..."
          @keydown="checkClose"
          @blur="close"
        />
        <Icon v-if="broadcasting" name="fa6-solid:volume-high" class="broadcasting" />
      </form>
      <div v-if="allResults.length" class="results">
        <div v-for="(res, index) in allResults" :key="res.id" class="result-row">
          <span v-if="res.player !== playerName" class="name">{{ res.player }}:</span>
          <DiceResult
            v-for="result in res.results"
            :key="`${result.expression}=${result.rolled}`"
            :result="result"
            :animated="index === 0 && !result.constant"
            class="result"
          />
        </div>
      </div>
    </div>
    <div v-else class="dice-overlay">
      <div v-if="filteredRolls.length" class="results container vertical">
        <div v-for="(res, index) in filteredRolls" :key="res.id" class="result-row" @click="clearRoll(res)">
          <div class="information">
            <span v-if="res.player !== playerName" class="name">{{ res.player }}</span>
            <span v-if="res.label" class="label">{{ res.label }}</span>
          </div>
          <DiceResult
            v-for="result in res.results"
            :key="`${result.expression}=${result.rolled}`"
            :result="result"
            :animated="index === 0 && !result.constant"
            condensed
          />
        </div>
      </div>
    </div>
  </client-only>
</template>

<style lang="postcss">
.global-dice-modal {
  position: fixed;

  display: flex;
  flex-direction: column;

  min-width: 400px;
  width: 650px;
  max-width: 90vw;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);

  margin: auto;
  z-index: 1000;

  .dice-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    box-shadow: var(--card-shadow);

    .dice-input {
      width: 100%;
      background: var(--background);
      outline: none !important;

      border: 2px solid var(--primary);
      border-radius: 0.5rem;

      padding: 0.5rem 1.5rem;

      font-size: 1.5rem;
      color: var(--text-highlight);
    }

    .broadcasting {
      position: absolute;
      right: 15px;
      color: var(--text-highlight);
    }
  }

  .results {
    margin-top: 1rem;

    max-height: 50vh;
    overflow-y: auto;

    background: var(--background);
    border: 2px solid var(--primary);
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    box-shadow: var(--card-shadow);

    .result-row {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .name {
        width: 100%;
        font-size: 0.8em;
        font-family: var(--font-stack-title);
        color: var(--text-highlight);
        padding: 0.25rem 0.75rem 0;
      }
    }
  }
}

.dice-overlay {
  position: fixed;
  bottom: 0;
  right: var(--dice-overlay-offset, 0);
  width: 500px;
  max-width: 100vh;
  z-index: 1000;
  pointer-events: none;

  .results {
    align-items: flex-end;
    margin-bottom: 16px;
    margin-right: 16px;
    gap: 8px;

    .result-row {
      background-color: var(--background);
      border: 2px solid var(--primary);
      border-radius: 16px;
      padding: 8px;
      pointer-events: all;
      display: flex;
      flex-direction: column;
      max-width: 80vw;

      .information {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 0 0.5rem;
        font-family: var(--font-stack-title);
        font-size: 0.8em;

        .name {
          color: var(--text-highlight);
        }

        .name,
        .label {
          margin-bottom: 0.25em;
        }
      }

      &:hover {
        opacity: var(--hover-opacity);
      }
    }
  }
}

.tabletop + .dice-overlay {
  right: 230px;
}

.tabletop.dm + .dice-overlay {
  right: 460px;
}
</style>
