<script setup lang="ts">
import AnimatedValue from './AnimatedValue.vue'
import type { ResultPart, SingleDiceResult } from '~/assets/dice/diceEval'

const props = defineProps<{
  result: SingleDiceResult
  animated?: boolean
  condensed?: boolean
}>()

const hover = ref(false)

const simplified = computed<ResultPart[]>(() => {
  if (!props.condensed || hover.value) return props.result.result
  return props.result.resultSimple
})
</script>

<template>
  <div
    class="dice-result"
    :class="{ condensed: condensed }"
    :title="result.expression"
    @mouseenter.prevent="hover = true"
    @mouseleave.prevent="hover = false"
  >
    <template v-for="(part, index) in simplified">
      <div
        v-if="part.type === 'die'"
        :key="'die' + index"
        class="part die"
        :class="part.die.tags.concat(['d' + part.die.sides])"
      >
        <AnimatedValue class="dice-value" :actual="part.die.value" :maximum="part.die.sides" :enabled="animated" />
        <Icon :name="`estia:d${part.die.sides}`" />
      </div>
      <div v-else-if="part.type === 'num'" :key="'num' + index" class="part expr number">{{ part.num }}</div>
      <div v-else-if="part.type === 'var'" :key="'var' + index" class="part expr number mod">
        <span class="mod-value">{{ part.value }}</span>
        <span class="mod-name">{{ part.name }}</span>
      </div>
      <Icon
        v-else-if="part.type === 'op'"
        :key="'op' + index"
        class="part expr"
        :name="{ '*': 'estia:times', '+': 'estia:plus', '-': 'estia:minus', '/': 'estia:divide' }[part.op] || ''"
      />
    </template>
    <div v-if="result !== null" class="part result">
      <Icon name="estia:equals" class="size-2x" />
      <AnimatedValue class="total" :actual="result.rolled" :maximum="result.rolled" :enabled="animated" />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.dice-result {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0.5rem;

  font-family: var(--font-stack-title);
  font-size: 0.75rem;
  gap: 0.5em 0.25em;

  .total {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 2em;
    padding: 0 0.5em;
    height: 2em;

    font-family: var(--font-stack-title);
    font-size: 2em;

    color: var(--text-highlight);

    border: 2px solid var(--primary);
    border-radius: 4px;
  }

  .expr {
    font-size: 1.5em;

    &.number {
      font-size: 2em;
    }

    &.icon {
      margin: 0 0.25rem;
    }

    &.mod {
      //display: flex; //flex-direction: column; //justify-content: center; //align-items: center; position: relative;

      .mod-name {
        position: absolute;
        bottom: 0;
        right: 50%;
        transform: translate(50%, 50%);
        font-size: 0.5em;
      }
    }
  }

  .result {
    display: flex;
    align-items: center;
    gap: 0.25em;

    .icon {
      margin: 0 0.25rem;
    }

    .total {
      box-shadow: 0 -2px var(--primary) inset;
    }
  }

  .die {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 1.75em;
    height: 1.75em;
    position: relative;

    font-family: var(--font-stack-title);
    font-size: 1.5em;

    color: var(--text-highlight);

    border: 2px solid var(--primary);
    border-radius: 4px;
    box-shadow: 0 -2px var(--primary) inset;

    &.explode {
      box-shadow: 0 0 10px var(--primary);
    }

    &.ignored {
      opacity: 0.5;
    }

    &.crit {
      --primary: var(--secondary);
    }

    &.maximum {
      background: var(--primary);
    }

    .icon {
      position: absolute;
      color: var(--text-highlight);
      font-size: 0.85em;
      bottom: 0;
      right: 0;
      width: 1rem;
      height: 1rem;
      transform: translate(50%, 50%);
      text-shadow: 1px 1px var(--background), -1px -1px var(--background);
    }
  }

  &.condensed {
    justify-content: flex-end;
  }
}
</style>
