<template>
  <div
    class="effect"
    :class="fog.rect.type || 'darkness'"
    :style="fogStyle"
    @click="preventDMsingleClick"
    @dblclick="disperseFog"
  ></div>
</template>

<script setup lang="ts">
import { type FogData } from './tabletopUtil'

const props = defineProps<{
  fog: FogData
}>()

const dm = useDM()

const { sendCommand } = useTabletop()

const fogStyle = computed(() => {
  const rect = props.fog.rect
  return {
    'grid-area': `${rect.y} / ${rect.x} / span ${rect.h} / span ${rect.w}`,
  }
})

const preventDMsingleClick = (ev: MouseEvent) => {
  if (dm.value && props.fog.rect.type === 'darkness') {
    ev.stopPropagation()
    return false
  }
  return true
}

const disperseFog = (ev: MouseEvent) => {
  if (!dm.value) return
  ev.stopPropagation()
  sendCommand('REMOVE_FOG', { id: props.fog.id })
}
</script>

<style scoped lang="postcss">
.effect {
  border: 2px solid black;
  z-index: 175;
  pointer-events: none;

  &.darkness {
    background: black;
  }

  &.fire {
    background: url(/img/fx/fire.gif);
    background-size: calc(4 * var(--grid-size));
    mix-blend-mode: overlay;
    border: 2px solid red;
  }

  &.sparkles {
    background: url(/img/fx/sparkles.gif);
    background-size: calc(1 * var(--grid-size));
    mix-blend-mode: screen;
    border: 2px solid white;
  }

  &.digital {
    background: url(/img/fx/digital.gif);
    background-size: calc(4 * var(--grid-size));
    mix-blend-mode: screen;
    border: 1px solid #ccc;
  }

  &.acid {
    background: url(/img/fx/green.gif);
    background-size: calc(4 * var(--grid-size));
    mix-blend-mode: screen;
    border: 1px solid #080;
  }

  &.leaves {
    background: url(/img/fx/leaves.gif);
    background-size: calc(2 * var(--grid-size));
    mix-blend-mode: screen;
    border: 1px solid #c80;
  }

  &.area {
    border: 2px solid currentColor;
    mix-blend-mode: screen;
    box-shadow: 0 0 20px currentColor inset;
    border-radius: 50%;

    &.red {
      color: var(--secondary);
    }
    &.blue {
      color: var(--primary);
    }
    &.green {
      color: green;
    }
  }

  &.night {
    border: none;
    mix-blend-mode: multiply;
    background: rgb(97, 114, 167);

    &:hover {
      opacity: 1 !important;
    }
  }

  &.light {
    border: none;
    mix-blend-mode: screen;
    background: radial-gradient(rgba(235, 182, 112, 0.8), transparent 75%);

    &:hover {
      opacity: 1 !important;
    }
  }
}
</style>

<style>
.dm {
  .effect {
    pointer-events: all;
  }

  .effect.darkness {
    opacity: 0.6;
    z-index: 175;
  }

  .effect:hover {
    opacity: 0.5;
  }
}
</style>
