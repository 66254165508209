import _ from 'lodash'
import { joinDice, resolveDice, type DiceExpr } from '~/assets/dice/diceEval'

export type Ability = 'str' | 'dex' | 'con' | 'int' | 'wis' | 'cha'

export type AbilityScoreData = {
  str: DiceExpr
  dex: DiceExpr
  con: DiceExpr
  int: DiceExpr
  wis: DiceExpr
  cha: DiceExpr
  hide?: boolean
}

export type Spellcasting = {
  stat: Ability
  progression: 'innate' | 'warlock' | 'full' | 'half' | 'third'
  level?: DiceExpr
  spells: Record<string, string[]>
  saveBonus?: DiceExpr
  attackBonus?: DiceExpr
  extra?: string
}

type SpeedData = { walk?: number; fly?: number; swim?: number; climb?: number }
type SenseData = { dark?: number; true?: number; blind?: number; tremor?: number }
type LevelData = Record<string, number>

export type BaseStats = {
  title: string
  generalName?: string
  level: number | LevelData
  prof?: number
  class?: string
  ac: number | string
  speed?: string | SpeedData
  hitdie?: string
  hitdice?: string
  bonushp?: number
  hp?: number
  nohp?: boolean
  initiative?: DiceExpr
  size: string
  abilities: AbilityScoreData
  saves?: string[]
  savesBonus?: DiceExpr
  skills?: string[]
  skillBonus?: DiceExpr
  expertise?: string[]
  resistances?: string | string[]
  immunities?: string | string[]
  vulnerabilities?: string | string[]
  conditions?: string | string[]
  languages?: string | string[]
  senses?: SenseData
  spellcasting?: Spellcasting | Spellcasting[]
  mods?: Record<string, number>
}

export type StatblockData = BaseStats & {
  alignment?: string
  type: string
  subtype?: string
  swarm?: boolean
  cr: number
  role?: string
  player?: boolean
  capacity?: string
  cargo?: string
}

export type CharacterSheetData = BaseStats & {
  portrait?: string
  customSkills?: Record<string, DiceExpr>
  jackOfAllTrades?: boolean
  customSaves?: Record<string, DiceExpr>
  toggles?: Record<string, Partial<CharacterSheetData>>
}

export const modifier = (attr: number): number => Math.floor((attr - 10) / 2)

export const totalLevel = (level: number | LevelData): number => {
  if (_.isNumber(level)) return level as number
  return Object.values(level).reduce((a, b) => a + b, 0)
}

export const getClassHitdie = (cls?: string): string | null => {
  const classes: Record<string, string> = {
    artificer: 'd8',
    bard: 'd8',
    barbarian: 'd12',
    cleric: 'd8',
    druid: 'd8',
    fighter: 'd10',
    monk: 'd8',
    ranger: 'd10',
    rogue: 'd8',
    paladin: 'd10',
    spellsword: 'd8',
    sorcerer: 'd6',
    warlock: 'd8',
    wizard: 'd6',
  }
  if (!cls) return null
  return classes[cls] || null
}

export function resolveMods(stats: BaseStats, extra: Record<string, DiceExpr> = {}): Record<string, DiceExpr> {
  if (!stats || !stats.abilities) return {}
  const abilities = _.mapValues(resolveAbilityScores(stats), modifier)
  const classLevels = _.isObject(stats.level) ? stats.level : stats.class ? { [stats.class]: stats.level } : {}

  const mods = {
    prof: proficiency(stats),
    level: totalLevel(stats.level),
    ...classLevels,
    ...abilities,
    ...(stats.mods || {}),
    ...extra,
  }

  return mods
}

export function proficiency(stats: BaseStats) {
  return stats.prof !== undefined ? stats.prof : 2 + Math.floor((totalLevel(stats.level) - 1) / 4)
}

function normalizeSkillname(skill: string) {
  return skill?.toLowerCase()?.replaceAll(' ', '')
}

export function skillExpr(stats: any, skill: string, passive: boolean = false): string {
  const skillNorm = normalizeSkillname(skill)
  const pass = passive ? '10' : ''
  const stat = skillStats[skillNorm]
  const prof = (stats.skills || []).map(normalizeSkillname).includes(skillNorm) ? 'prof' : ''
  const exp = (stats.expertise || []).map(normalizeSkillname).includes(skillNorm) ? 'prof' : ''

  return joinDice(pass, stat, prof, exp) as string
}

export function resolveAbilityScores(stats: BaseStats): Record<Ability, number> {
  const { str, dex, con, int, wis, cha } = stats.abilities
  return {
    str: resolveDice(str, {}, true),
    dex: resolveDice(dex, {}, true),
    con: resolveDice(con, {}, true),
    int: resolveDice(int, {}, true),
    wis: resolveDice(wis, {}, true),
    cha: resolveDice(cha, {}, true),
  }
}

export const emitRest = (...rests: string[]) => {
  rests.forEach((rest) => {
    const event = new CustomEvent('LimitedUseRest', { detail: { type: rest } })
    document.body.dispatchEvent(event)
  })
}

export const statnames: Record<Ability, string> = {
  str: 'Strength',
  dex: 'Dexterity',
  con: 'Constitution',
  int: 'Intelligence',
  wis: 'Wisdom',
  cha: 'Charisma',
}

export const skillStats: Record<string, Ability> = {
  athletics: 'str',
  acrobatics: 'dex',
  stealth: 'dex',
  'sleight of hand': 'dex',
  arcana: 'int',
  history: 'int',
  investigation: 'int',
  nature: 'int',
  religion: 'int',
  insight: 'wis',
  'animal handling': 'wis',
  medicine: 'wis',
  perception: 'wis',
  survival: 'wis',
  intimidation: 'cha',
  deception: 'cha',
  persuasion: 'cha',
  performance: 'cha',
}

export const hitDice: Record<string, string> = {
  tiny: 'd4',
  small: 'd6',
  medium: 'd8',
  large: 'd10',
  huge: 'd12',
  gargantuan: 'd20',
}

export const alignments: Record<string, string> = {
  lg: 'lawful good',
  ng: 'neutral good',
  cg: 'chaotic good',
  ln: 'lawful neutral',
  tn: 'true neutral',
  cn: 'chaotic neutral',
  le: 'lawful evil',
  ne: 'neutral evil',
  ce: 'chaotic evil',
}

export const resolveSpeed = (spd?: string | SpeedData, exhaustion: number = 0) => {
  if (!spd) return null
  const exh = exhaustion * 5
  const movementText = () => {
    if (spd.constructor === String) return spd
    const speed = spd as SpeedData
    const walk = speed.walk ? `${speed.walk - exh}ft` : null
    const climb = speed.climb ? `${speed.climb - exh}ft climb` : null
    const swim = speed.swim ? `${speed.swim - exh}ft swim` : null
    const flight = speed.fly ? `${speed.fly - exh}ft fly` : null

    return [walk, climb, swim, flight].filter((x) => !!x).join(', ')
  }
  const label = movementText()
  // should this be the highest value instead?
  if (spd.constructor === String)
    return {
      name: 'speed',
      icon: 'game-icons:run',
      value: spd,
      label,
    }
  const speed = spd as SpeedData
  if (speed.fly)
    return {
      name: 'fly',
      icon: 'game-icons:curly-wing',
      value: speed.fly - exh,
      label,
    }
  if (speed.climb)
    return {
      name: 'climb',
      icon: 'game-icons:squirrel',
      value: speed.climb - exh,
      label,
    }
  if (speed.swim)
    return {
      name: 'swim',
      icon: 'game-icons:shark-fin',
      value: speed.swim - exh,
      label,
    }
  return {
    name: 'speed',
    icon: 'game-icons:run',
    value: (speed.walk || 30) - exh,
    label,
  }
}

export const resolveSenses = (senses?: SenseData) => {
  const resolveLabel = () => {
    if (!senses) return 'No special senses'
    const darkvision = senses.dark ? `${senses.dark}ft darkvision` : null
    const blindsight = senses.blind ? `${senses.blind}ft blindsight` : null
    const tremorsense = senses.tremor ? `${senses.tremor}ft tremorsense` : null
    const truesight = senses.true ? `${senses.true}ft truesight` : null
    return [darkvision, blindsight, tremorsense, truesight].filter((x) => !!x).join(', ')
  }

  const noVision = {
    name: 'none',
    value: 0,
    icon: 'fa6-solid:eye',
    label: null,
  }

  if (!senses) return noVision

  const label = resolveLabel()

  if (senses.true)
    return {
      name: 'true',
      value: senses.true,
      icon: 'game-icons:sheikah-eye',
      label,
    }
  if (senses.blind)
    return {
      name: 'blind',
      value: senses.blind,
      icon: 'game-icons:radar-sweep',
      label,
    }
  if (senses.tremor)
    return {
      name: 'tremor',
      value: senses.tremor,
      icon: 'game-icons:dig-hole',
      label,
    }
  if (senses.dark)
    return {
      name: 'dark',
      value: senses.dark,
      icon: 'game-icons:semi-closed-eye',
      label,
    }
  return noVision
}

export const acRx = /([^(]*)(?: ?\(?([^)]*)\)?)?/

export default modifier
