<script setup lang="ts">
import type { ParsedContent } from '@nuxt/content'
import { rarityClass, titleCase } from './util'

const props = defineProps<{
  item: ParsedContent
}>()

const attunementText = computed(() => {
  if (!props.item.attunement) {
    return ''
  }
  if (props.item.attunement === true || props.item.attunement === 'yes') {
    return ' (requires attunement)'
  }
  return ` (requires attunement ${props.item.attunement})`
})

const typeText = computed(
  () => `${titleCase(props.item.type)}${props.item.subtype ? ` (${props.item.subtype}), ` : ', '}`
)
</script>

<template>
  <p class="subtitle subtitle--item">
    <span class="type">{{ typeText }}</span>
    <span class="rarity" :class="rarityClass(item.rarity)">{{ item.rarity }}</span>
    <span class="attunement">{{ attunementText }}</span>
  </p>
</template>

<style lang="postcss" scoped>
.subtitle--item {
  .type::first-letter {
    text-transform: uppercase;
  }
}
</style>
