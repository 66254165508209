<script setup lang="ts">
import { type CSSProperties } from 'vue'
import type { PoiData } from './content/InteractiveMap.vue'

const props = defineProps<{
  poi: PoiData
  title: string
  width: number
  height: number
  zoom: number
  viewportX: number
  viewportY: number
}>()

const pointStyle = computed<CSSProperties>(() => {
  const zoom = props.poi.static ? props.zoom : Math.sqrt(props.zoom)
  return {
    top: (-props.viewportY + props.poi.y / props.height) * props.zoom * 100 + '%',
    left: (-props.viewportX + props.poi.x / props.width) * props.zoom * 100 + '%',
    transform: `translate(-50%, -50%) scale(${zoom})`,
  }
})

const classes = computed(() => {
  return [
    `size-${props.poi.size !== undefined ? props.poi.size : '1'}`,
    props.poi.bg ? 'background' : '',
    props.poi.dmonly ? 'dm-only' : '',
    props.poi.class || '',
  ].join(' ')
})
</script>

<template>
  <div class="point-of-interest" :style="pointStyle" :class="classes">
    <div v-if="poi.icon === 'circle'" class="poi-icon circle"></div>
    <div v-else-if="poi.icon === 'square'" class="poi-icon square"></div>
    <Icon v-else-if="poi.icon" :name="poi.icon" class="poi-icon" />
    <WikiLink class="poi-name" :class="poi.position" :wiki="title" />
  </div>
</template>

<style scoped lang="postcss">
.notdm .point-of-interest.dm-only {
  display: none;
}

.point-of-interest {
  position: absolute;
  transform: translate(-50%, -50%);
  /* center on coordinates given instead of top-left */
  transform-origin: 50% 50%;
  mix-blend-mode: var(--poi-blend-mode, normal);
  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.75em;
  height: 1.75em;
  font-size: 1em;

  /*filter: drop-shadow(0 0 1px white);*/

  &.background {
    background: var(--icon-background);
    border: 1px solid var(--icon-color);
    border-radius: 50%;
  }

  &.size-0 {
    font-size: 0.5em;

    &.background::before {
      border-width: 1px;
    }
  }

  &.size-1 {
    font-size: 0.6em;

    &.background::before {
      border-width: 1px;
    }
  }

  &.size-2 {
    font-size: 0.8em;

    &.background::before {
      border-width: 1px;
    }
  }

  &.size-4 {
    font-size: 1.2em;
  }

  &.size-5 {
    font-size: 1.6em;
  }

  &.size-6 {
    font-size: 3em;
  }

  .poi-icon {
    color: var(--icon-color);
    display: block;
    position: relative;
    margin: 2px;
    z-index: 50;
    filter: drop-shadow(0 0 2px var(--background)) drop-shadow(0 0 1px var(--background));
    height: 100%;
    width: 100%;

    &.circle {
      background: var(--icon-background);
      border: 1.5px solid var(--icon-color);
      width: 1em;
      height: 1em;
      border-radius: 50%;
      filter: none;
      box-shadow: 0 0 2px var(--background), 0 0 1px var(--background);
    }

    &.square {
      background: var(--icon-background);
      border: 1.5px solid var(--icon-color);
      width: 0.8em;
      height: 0.8em;
      filter: none;
      box-shadow: 0 0 2px var(--background), 0 0 1px var(--background);
    }
  }

  .poi-name {
    position: absolute;
    left: 50%;
    top: 1em;
    transform: translateX(-50%);
    white-space: pre;
    text-align: center;
    z-index: 100;

    color: var(--icon-color);
    font-family: var(--font-stack-title);
    font-variation-settings: 'wdth' 4;
    letter-spacing: -0.5px;
    font-weight: 500;
    line-height: 1.1em;

    text-shadow: 0 0 4px var(--background), 0 0 4px var(--background), 0 0 4px var(--background),
      -1px -1px 2px var(--background, black), 1px 1px 2px var(--background, black), 0 0 1px var(--background);

    &.top {
      top: unset;
      bottom: 1em;
    }

    /* .bottom is default */

    &.left {
      top: unset;
      bottom: 0;
      left: unset;
      right: 1.5em;
      text-align: right;
      transform: unset;
    }

    &.right {
      top: unset;
      bottom: 0;
      left: 1.5em;
      text-align: left;
      transform: unset;
    }
  }

  &.highlight .poi-name {
    text-shadow: none;
  }

  &.underline .poi-name {
    text-decoration: underline;
    text-decoration-thickness: 0.075em;
    text-decoration-skip-ink: none;

    &::before {
      content: '';
    }
  }
}
</style>
