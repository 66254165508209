// https://nuxt.com/docs/api/configuration/nuxt-config
export default defineAppConfig({
  title: 'Estia',
  theme: {
    dark: true,
    colors: {
      primary: '#6495ed',
    },
  },
  icon: {
    mode: 'svg',
    class: 'icon',
    // https://icones.js.org/
    aliases: {
      artificer: 'game-icons:cog',
      barbarian: 'game-icons:battle-axe',
      bard: 'game-icons:harp',
      cleric: 'game-icons:angel-wings',
      druid: 'game-icons:flat-paw-print',
      fighter: 'game-icons:broadsword',
      monk: 'game-icons:fist',
      rogue: 'game-icons:broad-dagger',
      paladin: 'game-icons:black-knight-helm',
      ranger: 'game-icons:arrow-cluster',
      sorcerer: 'game-icons:unstable-orb',
      warlock: 'game-icons:warlock-eye',
      wizard: 'game-icons:pointy-hat',
      spellsword: 'game-icons:fire-spell-cast',
      psion: 'game-icons:brain',

      melee: 'game-icons:piercing-sword',
      ranged: 'game-icons:pocket-bow',

      bludgeoning: 'game-icons:thor-hammer',
      slashing: 'game-icons:battered-axe',
      piercing: 'game-icons:broad-dagger',
      physical: 'game-icons:crossed-swords',
      falling: 'game-icons:falling',

      fire: 'game-icons:fire',
      cold: 'game-icons:snowflake-2',
      thunder: 'game-icons:speaker',
      lightning: 'game-icons:lightning-storm',
      poison: 'game-icons:poison-bottle',
      acid: 'game-icons:acid',
      force: 'game-icons:falling-star',
      psychic: 'game-icons:psychic-waves',
      radiant: 'game-icons:sun',
      necrotic: 'game-icons:skull-crossed-bones',

      circle: 'fa6-regular:circle',
      sphere: 'fa6-regular:circle',
      cylinder: 'fa6-solid:database',
      line: 'fa6-solid:equals',
      cube: 'fa6-regular:square',
      cone: 'fa6-solid:less-than',
      emanation: 'fa6-solid:circle',

      str: 'game-icons:broadsword',
      dex: 'game-icons:pocket-bow',
      con: 'game-icons:heart-beats',
      int: 'game-icons:brain',
      wis: 'game-icons:wisdom',
      cha: 'game-icons:three-friends',

      water: 'game-icons:water-drop',
      air: 'game-icons:lightning-storm',
      earth: 'game-icons:rock',
      psionic: 'game-icons:psychic-waves',
      chaotic: 'game-icons:laser-sparks',
      lawful: 'game-icons:cross-shield',
      positive: 'game-icons:angel-outfit',
      negative: 'game-icons:crowned-skull',

      healing: 'game-icons:health-potion',

      freya: 'game-icons:sun',
      odin: 'game-icons:raven',
      skadi: 'game-icons:spiked-dragon-head',
      aegir: 'game-icons:wave-crest',
      hel: 'game-icons:crowned-skull',
      loki: 'game-icons:spider-alt',
      thor: 'game-icons:thor-hammer',
      sif: 'game-icons:life-in-the-balance',
      uller: 'game-icons:target-arrows',
      idun: 'game-icons:shiny-apple',
      heimdall: 'game-icons:anvil',
      balder: 'game-icons:lyre',
      tyr: 'game-icons:shard-sword',

      // spell effect icons, currently disabled
      frighten: 'ghost',
      charm: 'hand-holding-heart',
      stunned: 'spinner',
      blinded: 'eye-slash',
      deafen: 'deaf',
      disease: 'biohazard',
      curse: 'book-dead',
      exhaustion: 'bed',
      grappled: 'luchador',
      restrained: 'lasso',
      //invisible: '',
      //paralyzed: '',
      //petrified: '',
      //poisoned: '',
      //unconscious: '',

      buff: 'sunrise',
      debuff: 'sunset',
      warding: 'helmet-battle',
      //teleportation: '',
      //detection: '',
      //foreknowledge: '',
      //utility: '',
      //control: '',
      shapechanging: 'sheep',
      //deception: ''

      council: 'fa6-solid:building-columns',
    },
  },
})
