<script setup lang="ts">
const colorSchemeCookie = useCookie<string>('colorScheme', { maxAge: 60 * 60 * 24 * 365, default: () => 'default' })
</script>

<template>
  <div class="color-scheme">
    <input id="lightmode" v-model="colorSchemeCookie" type="radio" name="scheme" value="light" />
    <label for="lightmode" class="light" title="Light Mode">
      <Icon name="fa6-solid:lightbulb"></Icon>
    </label>
    <input id="default" v-model="colorSchemeCookie" type="radio" name="scheme" value="default" />
    <label for="default" class="default" title="Default Color Scheme">
      <Icon name="fa6-solid:lightbulb"></Icon>
    </label>
    <input id="darkmode" v-model="colorSchemeCookie" type="radio" name="scheme" value="dark" />
    <label for="darkmode" class="dark" title="Dark Mode">
      <Icon name="fa6-solid:lightbulb"></Icon>
    </label>
  </div>
</template>

<style scoped lang="postcss">
.color-scheme {
  display: flex;
  input {
    display: none;
  }

  label {
    opacity: 0.5;
    padding: 0.25rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: var(--primary);
    border-width: 2px 1px;
    border-style: solid;
    cursor: pointer;
  }

  label.light {
    color: #000;
    background-color: #fff;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-left-width: 2px;
  }

  label.default {
    background: linear-gradient(105deg, #fff, #fff 50%, #000 50%, #000);
  }

  label.dark {
    color: #fff;
    background-color: #000;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-right-width: 2px;
  }

  input:checked + label {
    opacity: 1;
    color: var(--primary);
  }
}
</style>
