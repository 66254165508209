<template>
  <div :key="pointer.x + '/' + pointer.y + '/' + pointer.color" class="pointer" :style="style">
    <span>{{ pointerText }}</span>
  </div>
</template>
<script setup lang="ts">
import { clamp } from '../util'
import { type Area, type Position } from './tabletopUtil'

export type PointerData = Area & { color: string }

const props = defineProps<{
  pointer: PointerData
  dimensions: Position
}>()

const style = computed(() => {
  const x = clamp(props.pointer.x, 0, props.dimensions.x)
  const y = clamp(props.pointer.y, 0, props.dimensions.y)
  return {
    color: props.pointer.color,
    'grid-column-start': x,
    'grid-column-end': `span ${props.pointer.w}`,
    'grid-row-start': y,
    'grid-row-end': `span ${props.pointer.h}`,
  }
})

const pointerText = computed(() => {
  if (props.pointer.w === 1 && props.pointer.h === 1) return ''
  return `${props.pointer.w * 5}x${props.pointer.h * 5}`
})
</script>

<style lang="postcss">
.pointer {
  z-index: 175;
  border: 2px solid currentColor;
  box-shadow: 0 0 15px currentColor inset;
  pointer-events: none;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  span {
    color: var(--text);
    font-family: var(--font-stack-title);
    margin: 0.25rem;
  }
}
</style>
