<script setup lang="ts">
import SpellFooter from '~/components/listings/SpellFooter.vue'

const { page } = useContent()

const type = computed(() => {
  if (page.value.isListing) return null
  if (page.value.race && page.value.class) return 'char'
  if (page.value._path?.startsWith('/homebrew/feats/')) return 'feat'
  if (page.value._path?.startsWith('/homebrew/items/')) return 'item'
  if (page.value._path?.startsWith('/homebrew/spells/')) return 'spell'

  return null
})
</script>

<template>
  <SpellFooter v-if="type === 'spell'" :spell="page"></SpellFooter>
</template>

<style lang="postcss" scoped></style>
