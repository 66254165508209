import _ from 'lodash'
import type { ParsedContent } from '@nuxt/content'
import { CURRENT_YEAR, joinParts, titleCase } from '../util'

export type Name = string

export type CharacterData = Partial<{
  name: Name
  title: string
  class: string
  subclass: string
  race: string
  subrace: string
  background: string
  alignment: string
  age: string | number
  born: string | number
  died: string | number
  children: Name[]
  spouse: Name
  minor: boolean
  indirect: boolean
  icons: string[]
}>

export type FamilyTree = Record<Name, CharacterData>

export const normalize = (name: Name, node: CharacterData): CharacterData => ({
  ...node,
  children: node?.children ?? [],
  name,
})

export const normalizeTree = (tree: FamilyTree): FamilyTree => {
  return _.mapValues(tree, (node, name) => normalize(name, node ?? { minor: true }))
}

export const treeGet = (tree: FamilyTree, key?: Name): CharacterData | null => {
  if (!key) return null
  if (tree[key]) return tree[key]
  return {
    name: key,
    minor: true,
    children: [],
  }
}

export const getInfo = (char?: CharacterData, simple = false, title = false) => {
  if (!char) return ''
  const raceclass = joinParts(' ', char.race, char.class)
  const text = joinParts(', ', title ? titleCase(raceclass) : raceclass, char.alignment?.toUpperCase(), ageText(char))
  if (simple) {
    return text
  } else {
    return `(${text}):`
  }
}

export const ageText = (char: CharacterData | ParsedContent): string => {
  if (char.born) {
    if (char.died) {
      return `${char.born}-${char.died}`
    } else if (_.isNumber(char.born)) {
      return `${CURRENT_YEAR - char.born}`
    } else {
      return char.born
    }
  } else {
    return char.age ? `${char.age}` : ''
  }
}
