/* AutoGenerated Code, changes may be overwritten
* INPUT GRAMMAR:
* TotalExpression := expression=Expression globalMods=GlobalMods?
* Expression := Multiply | Divide | DivideUp | Add | Subtract | Dice | Number
* Modifier := type='[a-z]' arg=Constant?
* GlobalMods := '!' modifiers=Modifier*
* Dice := amount=Number? 'd' sides=Number modifiers=Modifier*
* Multiply := lhs=Expression '\*' rhs=Expression
* Divide := lhs=Expression '/' rhs=Expression
* DivideUp := lhs=Expression '//' rhs=Expression
* Add := lhs=Expression '\+' rhs=Expression
* Subtract := lhs=Expression '-' rhs=Expression
* Variable := name='[a-z][a-z]+'
* Constant := value='-?[0-9]+'
* Number := Variable | Constant
*/
type Nullable<T> = T | null;
type $$RuleType<T> = () => Nullable<T>;
export interface ASTNodeIntf {
    kind: ASTKinds;
}
export enum ASTKinds {
    TotalExpression = "TotalExpression",
    Expression_1 = "Expression_1",
    Expression_2 = "Expression_2",
    Expression_3 = "Expression_3",
    Expression_4 = "Expression_4",
    Expression_5 = "Expression_5",
    Expression_6 = "Expression_6",
    Expression_7 = "Expression_7",
    Modifier = "Modifier",
    GlobalMods = "GlobalMods",
    Dice = "Dice",
    Multiply = "Multiply",
    Divide = "Divide",
    DivideUp = "DivideUp",
    Add = "Add",
    Subtract = "Subtract",
    Variable = "Variable",
    Constant = "Constant",
    Number_1 = "Number_1",
    Number_2 = "Number_2",
}
export interface TotalExpression {
    kind: ASTKinds.TotalExpression;
    expression: Expression;
    globalMods: Nullable<GlobalMods>;
}
export type Expression = Expression_1 | Expression_2 | Expression_3 | Expression_4 | Expression_5 | Expression_6 | Expression_7;
export type Expression_1 = Multiply;
export type Expression_2 = Divide;
export type Expression_3 = DivideUp;
export type Expression_4 = Add;
export type Expression_5 = Subtract;
export type Expression_6 = Dice;
export type Expression_7 = Number;
export interface Modifier {
    kind: ASTKinds.Modifier;
    type: string;
    arg: Nullable<Constant>;
}
export interface GlobalMods {
    kind: ASTKinds.GlobalMods;
    modifiers: Modifier[];
}
export interface Dice {
    kind: ASTKinds.Dice;
    amount: Nullable<Number>;
    sides: Number;
    modifiers: Modifier[];
}
export interface Multiply {
    kind: ASTKinds.Multiply;
    lhs: Expression;
    rhs: Expression;
}
export interface Divide {
    kind: ASTKinds.Divide;
    lhs: Expression;
    rhs: Expression;
}
export interface DivideUp {
    kind: ASTKinds.DivideUp;
    lhs: Expression;
    rhs: Expression;
}
export interface Add {
    kind: ASTKinds.Add;
    lhs: Expression;
    rhs: Expression;
}
export interface Subtract {
    kind: ASTKinds.Subtract;
    lhs: Expression;
    rhs: Expression;
}
export interface Variable {
    kind: ASTKinds.Variable;
    name: string;
}
export interface Constant {
    kind: ASTKinds.Constant;
    value: string;
}
export type Number = Number_1 | Number_2;
export type Number_1 = Variable;
export type Number_2 = Constant;
export class Parser {
    private readonly input: string;
    private pos: PosInfo;
    private negating: boolean = false;
    private memoSafe: boolean = true;
    constructor(input: string) {
        this.pos = {overallPos: 0, line: 1, offset: 0};
        this.input = input;
    }
    public reset(pos: PosInfo) {
        this.pos = pos;
    }
    public finished(): boolean {
        return this.pos.overallPos === this.input.length;
    }
    public clearMemos(): void {
        this.$scope$Expression$memo.clear();
    }
    protected $scope$Expression$memo: Map<number, [Nullable<Expression>, PosInfo]> = new Map();
    public matchTotalExpression($$dpth: number, $$cr?: ErrorTracker): Nullable<TotalExpression> {
        return this.run<TotalExpression>($$dpth,
            () => {
                let $scope$expression: Nullable<Expression>;
                let $scope$globalMods: Nullable<Nullable<GlobalMods>>;
                let $$res: Nullable<TotalExpression> = null;
                if (true
                    && ($scope$expression = this.matchExpression($$dpth + 1, $$cr)) !== null
                    && (($scope$globalMods = this.matchGlobalMods($$dpth + 1, $$cr)) || true)
                ) {
                    $$res = {kind: ASTKinds.TotalExpression, expression: $scope$expression, globalMods: $scope$globalMods};
                }
                return $$res;
            });
    }
    public matchExpression($$dpth: number, $$cr?: ErrorTracker): Nullable<Expression> {
        const fn = () => {
            return this.choice<Expression>([
                () => this.matchExpression_1($$dpth + 1, $$cr),
                () => this.matchExpression_2($$dpth + 1, $$cr),
                () => this.matchExpression_3($$dpth + 1, $$cr),
                () => this.matchExpression_4($$dpth + 1, $$cr),
                () => this.matchExpression_5($$dpth + 1, $$cr),
                () => this.matchExpression_6($$dpth + 1, $$cr),
                () => this.matchExpression_7($$dpth + 1, $$cr),
            ]);
        };
        const $scope$pos = this.mark();
        const memo = this.$scope$Expression$memo.get($scope$pos.overallPos);
        if(memo !== undefined) {
            this.reset(memo[1]);
            return memo[0];
        }
        const $scope$oldMemoSafe = this.memoSafe;
        this.memoSafe = false;
        this.$scope$Expression$memo.set($scope$pos.overallPos, [null, $scope$pos]);
        let lastRes: Nullable<Expression> = null;
        let lastPos: PosInfo = $scope$pos;
        for(;;) {
            this.reset($scope$pos);
            const res = fn();
            const end = this.mark();
            if(end.overallPos <= lastPos.overallPos)
                break;
            lastRes = res;
            lastPos = end;
            this.$scope$Expression$memo.set($scope$pos.overallPos, [lastRes, lastPos]);
        }
        this.reset(lastPos);
        this.memoSafe = $scope$oldMemoSafe;
        return lastRes;
    }
    public matchExpression_1($$dpth: number, $$cr?: ErrorTracker): Nullable<Expression_1> {
        return this.matchMultiply($$dpth + 1, $$cr);
    }
    public matchExpression_2($$dpth: number, $$cr?: ErrorTracker): Nullable<Expression_2> {
        return this.matchDivide($$dpth + 1, $$cr);
    }
    public matchExpression_3($$dpth: number, $$cr?: ErrorTracker): Nullable<Expression_3> {
        return this.matchDivideUp($$dpth + 1, $$cr);
    }
    public matchExpression_4($$dpth: number, $$cr?: ErrorTracker): Nullable<Expression_4> {
        return this.matchAdd($$dpth + 1, $$cr);
    }
    public matchExpression_5($$dpth: number, $$cr?: ErrorTracker): Nullable<Expression_5> {
        return this.matchSubtract($$dpth + 1, $$cr);
    }
    public matchExpression_6($$dpth: number, $$cr?: ErrorTracker): Nullable<Expression_6> {
        return this.matchDice($$dpth + 1, $$cr);
    }
    public matchExpression_7($$dpth: number, $$cr?: ErrorTracker): Nullable<Expression_7> {
        return this.matchNumber($$dpth + 1, $$cr);
    }
    public matchModifier($$dpth: number, $$cr?: ErrorTracker): Nullable<Modifier> {
        return this.run<Modifier>($$dpth,
            () => {
                let $scope$type: Nullable<string>;
                let $scope$arg: Nullable<Nullable<Constant>>;
                let $$res: Nullable<Modifier> = null;
                if (true
                    && ($scope$type = this.regexAccept(String.raw`(?:[a-z])`, "", $$dpth + 1, $$cr)) !== null
                    && (($scope$arg = this.matchConstant($$dpth + 1, $$cr)) || true)
                ) {
                    $$res = {kind: ASTKinds.Modifier, type: $scope$type, arg: $scope$arg};
                }
                return $$res;
            });
    }
    public matchGlobalMods($$dpth: number, $$cr?: ErrorTracker): Nullable<GlobalMods> {
        return this.run<GlobalMods>($$dpth,
            () => {
                let $scope$modifiers: Nullable<Modifier[]>;
                let $$res: Nullable<GlobalMods> = null;
                if (true
                    && this.regexAccept(String.raw`(?:!)`, "", $$dpth + 1, $$cr) !== null
                    && ($scope$modifiers = this.loop<Modifier>(() => this.matchModifier($$dpth + 1, $$cr), 0, -1)) !== null
                ) {
                    $$res = {kind: ASTKinds.GlobalMods, modifiers: $scope$modifiers};
                }
                return $$res;
            });
    }
    public matchDice($$dpth: number, $$cr?: ErrorTracker): Nullable<Dice> {
        return this.run<Dice>($$dpth,
            () => {
                let $scope$amount: Nullable<Nullable<Number>>;
                let $scope$sides: Nullable<Number>;
                let $scope$modifiers: Nullable<Modifier[]>;
                let $$res: Nullable<Dice> = null;
                if (true
                    && (($scope$amount = this.matchNumber($$dpth + 1, $$cr)) || true)
                    && this.regexAccept(String.raw`(?:d)`, "", $$dpth + 1, $$cr) !== null
                    && ($scope$sides = this.matchNumber($$dpth + 1, $$cr)) !== null
                    && ($scope$modifiers = this.loop<Modifier>(() => this.matchModifier($$dpth + 1, $$cr), 0, -1)) !== null
                ) {
                    $$res = {kind: ASTKinds.Dice, amount: $scope$amount, sides: $scope$sides, modifiers: $scope$modifiers};
                }
                return $$res;
            });
    }
    public matchMultiply($$dpth: number, $$cr?: ErrorTracker): Nullable<Multiply> {
        return this.run<Multiply>($$dpth,
            () => {
                let $scope$lhs: Nullable<Expression>;
                let $scope$rhs: Nullable<Expression>;
                let $$res: Nullable<Multiply> = null;
                if (true
                    && ($scope$lhs = this.matchExpression($$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:\*)`, "", $$dpth + 1, $$cr) !== null
                    && ($scope$rhs = this.matchExpression($$dpth + 1, $$cr)) !== null
                ) {
                    $$res = {kind: ASTKinds.Multiply, lhs: $scope$lhs, rhs: $scope$rhs};
                }
                return $$res;
            });
    }
    public matchDivide($$dpth: number, $$cr?: ErrorTracker): Nullable<Divide> {
        return this.run<Divide>($$dpth,
            () => {
                let $scope$lhs: Nullable<Expression>;
                let $scope$rhs: Nullable<Expression>;
                let $$res: Nullable<Divide> = null;
                if (true
                    && ($scope$lhs = this.matchExpression($$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:/)`, "", $$dpth + 1, $$cr) !== null
                    && ($scope$rhs = this.matchExpression($$dpth + 1, $$cr)) !== null
                ) {
                    $$res = {kind: ASTKinds.Divide, lhs: $scope$lhs, rhs: $scope$rhs};
                }
                return $$res;
            });
    }
    public matchDivideUp($$dpth: number, $$cr?: ErrorTracker): Nullable<DivideUp> {
        return this.run<DivideUp>($$dpth,
            () => {
                let $scope$lhs: Nullable<Expression>;
                let $scope$rhs: Nullable<Expression>;
                let $$res: Nullable<DivideUp> = null;
                if (true
                    && ($scope$lhs = this.matchExpression($$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?://)`, "", $$dpth + 1, $$cr) !== null
                    && ($scope$rhs = this.matchExpression($$dpth + 1, $$cr)) !== null
                ) {
                    $$res = {kind: ASTKinds.DivideUp, lhs: $scope$lhs, rhs: $scope$rhs};
                }
                return $$res;
            });
    }
    public matchAdd($$dpth: number, $$cr?: ErrorTracker): Nullable<Add> {
        return this.run<Add>($$dpth,
            () => {
                let $scope$lhs: Nullable<Expression>;
                let $scope$rhs: Nullable<Expression>;
                let $$res: Nullable<Add> = null;
                if (true
                    && ($scope$lhs = this.matchExpression($$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:\+)`, "", $$dpth + 1, $$cr) !== null
                    && ($scope$rhs = this.matchExpression($$dpth + 1, $$cr)) !== null
                ) {
                    $$res = {kind: ASTKinds.Add, lhs: $scope$lhs, rhs: $scope$rhs};
                }
                return $$res;
            });
    }
    public matchSubtract($$dpth: number, $$cr?: ErrorTracker): Nullable<Subtract> {
        return this.run<Subtract>($$dpth,
            () => {
                let $scope$lhs: Nullable<Expression>;
                let $scope$rhs: Nullable<Expression>;
                let $$res: Nullable<Subtract> = null;
                if (true
                    && ($scope$lhs = this.matchExpression($$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:-)`, "", $$dpth + 1, $$cr) !== null
                    && ($scope$rhs = this.matchExpression($$dpth + 1, $$cr)) !== null
                ) {
                    $$res = {kind: ASTKinds.Subtract, lhs: $scope$lhs, rhs: $scope$rhs};
                }
                return $$res;
            });
    }
    public matchVariable($$dpth: number, $$cr?: ErrorTracker): Nullable<Variable> {
        return this.run<Variable>($$dpth,
            () => {
                let $scope$name: Nullable<string>;
                let $$res: Nullable<Variable> = null;
                if (true
                    && ($scope$name = this.regexAccept(String.raw`(?:[a-z][a-z]+)`, "", $$dpth + 1, $$cr)) !== null
                ) {
                    $$res = {kind: ASTKinds.Variable, name: $scope$name};
                }
                return $$res;
            });
    }
    public matchConstant($$dpth: number, $$cr?: ErrorTracker): Nullable<Constant> {
        return this.run<Constant>($$dpth,
            () => {
                let $scope$value: Nullable<string>;
                let $$res: Nullable<Constant> = null;
                if (true
                    && ($scope$value = this.regexAccept(String.raw`(?:-?[0-9]+)`, "", $$dpth + 1, $$cr)) !== null
                ) {
                    $$res = {kind: ASTKinds.Constant, value: $scope$value};
                }
                return $$res;
            });
    }
    public matchNumber($$dpth: number, $$cr?: ErrorTracker): Nullable<Number> {
        return this.choice<Number>([
            () => this.matchNumber_1($$dpth + 1, $$cr),
            () => this.matchNumber_2($$dpth + 1, $$cr),
        ]);
    }
    public matchNumber_1($$dpth: number, $$cr?: ErrorTracker): Nullable<Number_1> {
        return this.matchVariable($$dpth + 1, $$cr);
    }
    public matchNumber_2($$dpth: number, $$cr?: ErrorTracker): Nullable<Number_2> {
        return this.matchConstant($$dpth + 1, $$cr);
    }
    public test(): boolean {
        const mrk = this.mark();
        const res = this.matchTotalExpression(0);
        const ans = res !== null;
        this.reset(mrk);
        return ans;
    }
    public parse(): ParseResult {
        const mrk = this.mark();
        const res = this.matchTotalExpression(0);
        if (res)
            return {ast: res, errs: []};
        this.reset(mrk);
        const rec = new ErrorTracker();
        this.clearMemos();
        this.matchTotalExpression(0, rec);
        const err = rec.getErr()
        return {ast: res, errs: err !== null ? [err] : []}
    }
    public mark(): PosInfo {
        return this.pos;
    }
    // @ts-ignore: loopPlus may not be called
    private loopPlus<T>(func: $$RuleType<T>): Nullable<[T, ...T[]]> {
        return this.loop(func, 1, -1) as Nullable<[T, ...T[]]>;
    }
    private loop<T>(func: $$RuleType<T>, lb: number, ub: number): Nullable<T[]> {
        const mrk = this.mark();
        const res: T[] = [];
        while (ub === -1 || res.length < ub) {
            const preMrk = this.mark();
            const t = func();
            if (t === null || this.pos.overallPos === preMrk.overallPos) {
                break;
            }
            res.push(t);
        }
        if (res.length >= lb) {
            return res;
        }
        this.reset(mrk);
        return null;
    }
    private run<T>($$dpth: number, fn: $$RuleType<T>): Nullable<T> {
        const mrk = this.mark();
        const res = fn()
        if (res !== null)
            return res;
        this.reset(mrk);
        return null;
    }
    // @ts-ignore: choice may not be called
    private choice<T>(fns: Array<$$RuleType<T>>): Nullable<T> {
        for (const f of fns) {
            const res = f();
            if (res !== null) {
                return res;
            }
        }
        return null;
    }
    private regexAccept(match: string, mods: string, dpth: number, cr?: ErrorTracker): Nullable<string> {
        return this.run<string>(dpth,
            () => {
                const reg = new RegExp(match, "y" + mods);
                const mrk = this.mark();
                reg.lastIndex = mrk.overallPos;
                const res = this.tryConsume(reg);
                if(cr) {
                    cr.record(mrk, res, {
                        kind: "RegexMatch",
                        // We substring from 3 to len - 1 to strip off the
                        // non-capture group syntax added as a WebKit workaround
                        literal: match.substring(3, match.length - 1),
                        negated: this.negating,
                    });
                }
                return res;
            });
    }
    private tryConsume(reg: RegExp): Nullable<string> {
        const res = reg.exec(this.input);
        if (res) {
            let lineJmp = 0;
            let lind = -1;
            for (let i = 0; i < res[0].length; ++i) {
                if (res[0][i] === "\n") {
                    ++lineJmp;
                    lind = i;
                }
            }
            this.pos = {
                overallPos: reg.lastIndex,
                line: this.pos.line + lineJmp,
                offset: lind === -1 ? this.pos.offset + res[0].length : (res[0].length - lind - 1)
            };
            return res[0];
        }
        return null;
    }
    // @ts-ignore: noConsume may not be called
    private noConsume<T>(fn: $$RuleType<T>): Nullable<T> {
        const mrk = this.mark();
        const res = fn();
        this.reset(mrk);
        return res;
    }
    // @ts-ignore: negate may not be called
    private negate<T>(fn: $$RuleType<T>): Nullable<boolean> {
        const mrk = this.mark();
        const oneg = this.negating;
        this.negating = !oneg;
        const res = fn();
        this.negating = oneg;
        this.reset(mrk);
        return res === null ? true : null;
    }
    // @ts-ignore: Memoise may not be used
    private memoise<K>(rule: $$RuleType<K>, memo: Map<number, [Nullable<K>, PosInfo]>): Nullable<K> {
        const $scope$pos = this.mark();
        const $scope$memoRes = memo.get($scope$pos.overallPos);
        if(this.memoSafe && $scope$memoRes !== undefined) {
        this.reset($scope$memoRes[1]);
        return $scope$memoRes[0];
        }
        const $scope$result = rule();
        if(this.memoSafe)
        memo.set($scope$pos.overallPos, [$scope$result, this.mark()]);
        return $scope$result;
    }
}
export function parse(s: string): ParseResult {
    const p = new Parser(s);
    return p.parse();
}
export interface ParseResult {
    ast: Nullable<TotalExpression>;
    errs: SyntaxErr[];
}
export interface PosInfo {
    readonly overallPos: number;
    readonly line: number;
    readonly offset: number;
}
export interface RegexMatch {
    readonly kind: "RegexMatch";
    readonly negated: boolean;
    readonly literal: string;
}
export type EOFMatch = { kind: "EOF"; negated: boolean };
export type MatchAttempt = RegexMatch | EOFMatch;
export class SyntaxErr {
    public pos: PosInfo;
    public expmatches: MatchAttempt[];
    constructor(pos: PosInfo, expmatches: MatchAttempt[]) {
        this.pos = pos;
        this.expmatches = [...expmatches];
    }
    public toString(): string {
        return `Syntax Error at line ${this.pos.line}:${this.pos.offset}. Expected one of ${this.expmatches.map(x => x.kind === "EOF" ? " EOF" : ` ${x.negated ? 'not ': ''}'${x.literal}'`)}`;
    }
}
class ErrorTracker {
    private mxpos: PosInfo = {overallPos: -1, line: -1, offset: -1};
    private regexset: Set<string> = new Set();
    private pmatches: MatchAttempt[] = [];
    public record(pos: PosInfo, result: any, att: MatchAttempt) {
        if ((result === null) === att.negated)
            return;
        if (pos.overallPos > this.mxpos.overallPos) {
            this.mxpos = pos;
            this.pmatches = [];
            this.regexset.clear()
        }
        if (this.mxpos.overallPos === pos.overallPos) {
            if(att.kind === "RegexMatch") {
                if(!this.regexset.has(att.literal))
                    this.pmatches.push(att);
                this.regexset.add(att.literal);
            } else {
                this.pmatches.push(att);
            }
        }
    }
    public getErr(): SyntaxErr | null {
        if (this.mxpos.overallPos !== -1)
            return new SyntaxErr(this.mxpos, this.pmatches);
        return null;
    }
}