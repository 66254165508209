import { defineStore } from 'pinia'
import type { TabletopData } from '~/components/tabletop/tabletopUtil'

type PlayerData = {
  name: string
  color: string
  broadcastDice: boolean
}

export const useStore = defineStore('estia', {
  state: () => ({
    collapsed: {} as Record<string, boolean>,
    limitedUse: {} as Record<string, string>,
    table: { map: {}, players: {}, characters: {} } as TabletopData,
    playerData: { name: 'Player', color: '#e27e29', broadcastDice: false } as PlayerData,
  }),
  persist: true,
})
