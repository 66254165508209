<template>
  <span class="tag">
    {{ props.tag }}
  </span>
</template>

<script setup lang="ts">
const props = defineProps<{
  tag: string
}>()
</script>

<style scoped lang="postcss">
.tag {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--primary);
  padding: 0.25em 0.5em;
  margin-right: 0.66rem;
  font-size: 0.75rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
}
</style>
