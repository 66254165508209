<script setup lang="ts">
const { page, toc } = useContent()
</script>

<template>
  <nav class="menu table-of-contents">
    <div v-if="toc && toc.links.length > 0" class="toc-content">
      <img class="ornament" src="/img/ornament.png" alt="ornament" />

      <a href="#">
        <h3>{{ page.title }}</h3>
      </a>

      <ul v-if="toc && toc.links" class="toc root">
        <TableOfContentsItem v-for="node in toc.links" :key="node._id" :node="node" />
      </ul>

      <img class="ornament" src="/img/ornament-inverted.png" alt="ornament" />
    </div>
  </nav>
</template>

<style lang="postcss">
.table-of-contents {
  .toc {
    list-style: none;
    margin: 0.2em 0 0 0;
    padding-left: 0;
    overflow-x: auto;

    a {
      display: block;
    }
  }
}
</style>

<style scoped lang="postcss">
.table-of-contents {
  position: sticky;
  top: 8px;
  max-height: 100vh;
  grid-area: toc;
  margin-left: 1em;

  @media screen and (max-width: 1400px) {
    display: none;
  }

  @media print {
    display: none;
  }

  .toc-content {
    height: 100%;
    max-width: var(--sidebar-width);
    padding: 1em 1em 2em;
    display: flex;
    flex-direction: column;

    h3 {
      align-self: stretch;
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      border-bottom: 2px solid var(--primary);
    }

    .toc.root {
      margin-top: 0;
      margin-bottom: 1em;
    }
  }
}
</style>
