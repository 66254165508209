<script setup lang="ts">
import { type Node, sortLinks } from './util'

const props = defineProps<{
  node: Node
}>()

const isPage = computed(() => props.node._id !== undefined)

const dm = useDM()

const children = computed(() => {
  if (!props.node.children) return []
  if (props.node.isListing) return []

  const children: Node[] = sortLinks(props.node.children, dm.value)

  return children
})

const id = props.node._path
const navStore = useStore()
const collapsed = computed(() => navStore.collapsed[id] ?? false)
const toggleCollapsed = () => {
  navStore.collapsed[id] = !collapsed.value
}
</script>

<template>
  <li v-if="isPage || children.length" class="node">
    <span class="node-title">
      <Icon
        v-if="children.length"
        name="estia:triquetra"
        class="chevron"
        :class="{ 'rotate-90': !collapsed }"
        @click="toggleCollapsed"
      />
      <NuxtLink v-if="isPage" :to="node._path" no-prefetch class="node-link">
        <Icon v-if="node.icon" :name="node.icon" class="node-icon" />{{ node.title }}
      </NuxtLink>
      <span v-else class="node-label" @click="toggleCollapsed">
        <Icon v-if="node.icon" :name="node.icon" class="node-icon" />{{ node.title }}
      </span>
    </span>
    <ul v-if="children.length && !collapsed" class="node-list">
      <SidebarLink v-for="child in children" :key="child._id" :node="child" />
    </ul>
  </li>
</template>

<style scoped lang="postcss">
.node {
  .node-title {
    display: flex;
    align-items: center;
  }

  .node-link {
    font-size: 1em;
    font-family: var(--font-stack-title);
    font-variation-settings: 'wdth' 5;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.125em;

    &.router-link-active {
      color: var(--secondary);

      .node-icon {
        color: var(--text-highlight);
      }
    }
  }

  .node-label {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 0.75em;
    margin-top: 0.33em;
    margin-bottom: 0.33em;
  }

  .node-icon {
    color: var(--text);
    /* width: 1.5em; */
    margin-right: 0.5em;
  }
}

.chevron {
  margin: 0 0.5em 0 0;
  transition: transform 0.4s ease-in;
}
</style>
