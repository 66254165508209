<script setup lang="ts">
import type { ParsedContent } from '@nuxt/content'
import { arrayify, titleCase } from './util'

const props = defineProps<{
  item: ParsedContent
}>()

type MaterialPage = {
  essence?: string[]
  category?: string
}

const subtitle = computed(() => {
  const essences = titleCase(arrayify((props.item as MaterialPage).essence).join(', '))
  if (!essences) return ''
  return `Essence: ${essences}`
})
</script>

<template>
  <p v-if="subtitle" class="subtitle subtitle--material">
    {{ subtitle }}
  </p>
</template>

<style scoped lang="postcss">
.subtitle--material {
  font-style: italic;
}
</style>
