import type { DropHandler, TabletopData } from '~/components/tabletop/tabletopUtil'

export default () => {
  const state = useStore()

  const table = computed<TabletopData>(() => state.table)
  const dimensions = computed(() => table.value?.map?.dimensions ?? { x: 0, y: 0 })

  const sendCommand = inject<(cmd: string, data?: any) => any>('sendCommand', (_cmd: string) => {})
  const onDrop = inject<(handler: DropHandler) => void>('onDrop', (_: DropHandler) => {})

  return { table, dimensions, sendCommand, onDrop }
}
