<script setup lang="ts">
import type { NavItem } from '@nuxt/content'
import Sidebar from '~/components/SidebarMenu.vue'
import TableOfContents from '~/components/TableOfContents.vue'
import { type SearchItems, flattenNavigation } from '~/components/util'
import GlobalDiceRoller from '~/components/dice/GlobalDiceRoller.vue'
import type { Name, FamilyTreeData } from '~/components/familytree/familytree'

const { page, navigation } = useContent()

const dm = useDM()

if (!page.value || !(page.value.public || dm.value)) {
  navigateTo('/')
}

const peopleData = computed<Record<Name, FamilyTreeData>>(() => {
  const pages = flattenNavigation(navigation.value)
  const allPeople = pages.filter((p) => p.people).map((page) => page.people)
  const result = Object.assign({}, ...allPeople)
  return result
})

const searchData = computed((): SearchItems => {
  const pages = flattenNavigation(navigation.value)
  const res: Record<string, NavItem> = {}

  pages
    .filter((page) => page.public || dm.value)
    .forEach((page) => {
      const tags = Array.isArray(page.searchTags) ? page.searchTags : Object.keys(page.searchTags ?? {})
      const allTags = new Set<string>([page.searchTitle, ...tags])
      allTags.forEach((tag) => {
        if (res[tag]) console.warn('tag already exists:', tag)
        res[tag] = page
      })
    })
  return res
})

provide('search', searchData)
provide('people', peopleData)

const colorScheme = useCookie<string>('colorScheme', { maxAge: 60 * 60 * 24 * 365, default: () => 'default' })
</script>

<template>
  <main :class="{ dm: dm, notdm: !dm, lightmode: colorScheme === 'light', darkmode: colorScheme === 'dark' }">
    <Sidebar />
    <div id="hero"></div>
    <slot />
    <TableOfContents />
    <GlobalDiceRoller />
    <GlobalPagePreview />
  </main>
</template>

<style lang="postcss">
#hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding-top: 1.5em;
  grid-area: hero;

  &:empty {
    padding: 0;
  }
}

main {
  padding: 0.5em;

  @media (min-width: 1400px) {
    display: grid;
    grid-template-areas: '. menu hero hero .' '. menu article toc .';
    grid-template-columns: 1fr var(--sidebar-width) minmax(25em, 50em) var(--sidebar-width) 1fr;
    grid-template-rows: minmax(0, min-content) 1fr;
  }

  @media (min-width: 950px) and (max-width: 1399px) {
    display: grid;
    grid-template-areas: '. menu hero .' '. menu article .';
    grid-template-columns: 1fr var(--sidebar-width) minmax(25em, 50em) 1fr;
    grid-template-rows: minmax(0, min-content);
  }

  @media (max-width: 949px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  article {
    max-width: 50em;
    padding-top: 2em;
    padding-bottom: 5em;

    grid-area: article;

    @media screen and (max-width: 950px) {
      flex: 1;
      max-width: 100%;
      padding-top: 1em;
    }
  }
}
</style>
