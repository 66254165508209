<template>
  <td class="cell">
    <div class="cell-title">{{ props.title }}</div>
    <div class="cell-value">
      <slot />
    </div>
  </td>
</template>

<script setup lang="ts">
const props = defineProps<{
  title: string
}>()
</script>

<style lang="postcss">
.cell {
  padding: 0.5rem 0.5rem;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  .cell-title {
    font-weight: 500;
    font-size: 0.9em;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-highlight);
  }

  .cell-value {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-direction: row;
    align-items: center;

    .icon {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
</style>
