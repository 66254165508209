<template>
  <WikiLink :wiki="props.link" :icon="icon" class="link-card" />
</template>

<script setup lang="ts">
const props = defineProps<{
  link: string
  icon?: string
}>()
</script>

<style scoped lang="postcss">
.link-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary);
  border-bottom: 4px solid var(--primary);
  box-shadow: var(--card-shadow);
  font-family: var(--font-stack-title);
  padding: 0.5em 1em;
  flex: 1 1 25%;
  text-align: center;

  &:hover {
    --primary: var(--secondary);
  }

  @media screen and (max-width: 600px) {
    flex: 1 1 40%;
    padding: 0.75em;
  }
}
</style>

<style lang="postcss">
.link-cards {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  gap: 1em;

  .icon {
    width: 1.5em;
    height: 1.5em;
  }
}
</style>
