<template>
  <svg v-if="conditions.length" viewBox="0 0 100 100" :class="conditionClass + ' size-' + size">
    <defs>
      <mask id="hole">
        <rect width="100%" height="100%" fill="white"></rect>
        <circle cx="50" cy="50" r="38" fill="black"></circle>
      </mask>
    </defs>
    <circle :class="'disk ' + conditionClass" cx="50" cy="50" r="50" mask="url(#hole)"></circle>
    <circle id="curve" cx="50" cy="50" r="39" transform="rotate(90 50 50)" fill="transparent"></circle>
    <text width="100" text-anchor="middle">
      <textPath xlink:href="#curve" startOffset="50%">
        {{ conditionText }}
      </textPath>
    </text>
  </svg>
</template>

<script setup lang="ts">
const props = defineProps<{
  conditions: string[]
  size?: string | number
}>()

const conditionClass = computed(() => props.conditions.map((c) => c.replace(/[ ']/g, '')).join(' '))
const conditionText = computed(() => props.conditions.join(' '))
</script>

<style scoped lang="postcss">
svg {
  pointer-events: none;
  position: absolute;
  z-index: 50;
  opacity: 0.7;

  width: calc(var(--size) * 4 / 3 * var(--grid-size));
  animation: condition-spin infinite linear;
  animation-duration: calc(var(--size) * 15s);

  text {
    font-family: var(--font-stack-title);
    font-size: 0.9rem;
    user-select: none;
    text-transform: capitalize;
    fill: white;
    filter: drop-shadow(0 0 1px black);
  }

  .disk.bless {
    fill: rgba(100, 149, 237, 0.8);
  }

  .disk.bane {
    fill: rgba(0, 0, 0, 0.8);
  }

  .disk.hexbladescurse {
    fill: rgba(165, 65, 204, 0.93);
  }

  .disk.hex {
    fill: rgba(135, 69, 204, 0.8);
  }

  .disk.huntersmark {
    fill: rgba(21, 107, 18, 0.8);
  }

  .disk.stunned {
    fill: rgba(199, 199, 53, 0.8);
  }

  .disk.poisoned {
    fill: rgba(31, 236, 34, 0.53);
  }

  .disk.paralyzed {
    fill: rgba(236, 203, 170, 0.53);
  }

  .disk.prone {
    fill: rgba(132, 236, 37, 0.53);
  }

  .disk.incapacitated {
    fill: rgba(255, 255, 255, 0.53);
  }

  .disk.frightened {
    fill: rgba(133, 41, 43, 0.88);
  }

  .disk.charmed {
    fill: rgba(255, 73, 184, 0.88);
  }

  .disk.restrained {
    fill: rgba(34, 255, 169, 0.88);
  }

  .disk.invisible {
    fill: rgba(199, 195, 158, 0.58);
  }

  .disk.blinded {
    fill: rgba(251, 255, 46, 0.88);
  }

  .disk.deafened {
    fill: rgba(110, 69, 26, 0.76);
  }

  .disk.grappled {
    fill: rgba(57, 13, 255, 0.88);
  }

  .disk.petrified {
    fill: rgba(255, 203, 139, 0.88);
  }

  .disk.flying {
    fill: rgba(96, 82, 82, 0.5);
  }

  .disk.concentration {
    fill: rgba(255, 45, 35, 0.8);
  }

  .disk.bloodied {
    fill: rgba(192, 27, 19, 0.85);
  }

  .disk.burning {
    fill: rgba(255, 101, 35, 0.8);
  }
}

@keyframes condition-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
