<template>
  <div :class="['character', ...char.conditions]" draggable="true" :style="creatureStyle" :data-id="char.id">
    <Condition :conditions="char.conditions" :size="props.char.size" />
    <div v-if="char.aura > 0" class="aura" :style="auraStyle"></div>
    <div class="name">{{ char.name }}{{ number }}</div>
  </div>
</template>

<script setup lang="ts">
import { type CharData, computePosition, contains } from './tabletopUtil'
import Condition from './Condition.vue'

const props = defineProps<{
  char: CharData
  dungeonMaster: boolean
}>()

const { table } = useTabletop()

const isVisible = computed(() => {
  if (props.dungeonMaster) return true

  return (
    Object.values(table.value.map.fog)
      .filter((fog) => fog.rect.type === 'darkness')
      .findIndex((fog) => contains(fog.rect, props.char.position)) === -1
  )
})

const size = computed(() => {
  if (props.char.size === '0' || props.char.size === 0) return '0.75'
  return props.char.size
})

const creatureStyle = computed(() => ({
  display: isVisible.value ? 'flex' : 'none',
  '--size': size.value,
  '--sprite': props.char.sprite || '#f0f',
  ...style.value,
}))

const auraStyle = computed(() => ({
  '--aura-radius': props.char.aura,
}))

const position = computed(() => computePosition(table.value, props.char))

const style = computed(() => {
  if (!table.value.map.dimensions) return

  const [x, y] = position.value
  const zIndex = props.char.mountedOn ? 210 : 200
  return {
    'grid-column-start': x,
    'grid-column-end': `span ${size.value}`,
    'grid-row-start': y,
    'grid-row-end': `span ${size.value}`,
    'z-index': zIndex,
  }
})

const number = computed(() => {
  if (!props.char.number) return '' // for newCreature
  const chars = Object.values(table.value.characters)
  const sameName = chars.filter((c) => c.name === props.char.name)
  if (sameName.length < 2) return ''
  return ` ${props.char.number}`
})
</script>

<style scoped lang="postcss">
.character {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  user-select: none;
  border-radius: 50%;

  transition: transform 1s, opacity 0.5s;

  &:before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    inset: 0;
    width: calc(var(--size) * var(--grid-size));
    height: calc(var(--size) * var(--grid-size));
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border: 2px solid black;
    border-radius: 50%;
    background: var(--sprite, var(--primary));
    background-size: cover !important;
    box-shadow: 0 0 8px black;
  }

  &.editing:before {
    border: 2px solid white;
  }

  .name {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    font-family: var(--font-stack-title);
    text-transform: capitalize;
    font-size: calc(0.75em + (1em * var(--size) / 4));
    color: white;
    text-shadow: 0 0 2px black, 0 0 1px black;
    text-align: center;
    z-index: 250;
    pointer-events: none;
    white-space: pre;
  }

  .aura {
    --aura-radius: 1;
    position: absolute;
    pointer-events: none;
    width: calc(var(--grid-size) * (round(var(--size)) + 2 * var(--aura-radius)));
    height: calc(var(--grid-size) * (round(var(--size)) + 2 * var(--aura-radius)));
    top: calc(-1 * var(--grid-size) * var(--aura-radius));
    left: calc(-1 * var(--grid-size) * var(--aura-radius));
    box-shadow: 0 0 30px #fffc inset;
    border: 1px solid #fffc;
    border-radius: 50%;
    z-index: 50;
  }

  &.prone {
    transform: rotate(90deg);
    transform-origin: center;
  }

  &.invisible {
    opacity: 0.5;
  }

  &.petrified {
    filter: saturate(0.33);
  }
}
</style>
