<script setup lang="ts">
import { rarityClass } from './util'

const { page } = useContent()

const props = defineProps<{
  h1class?: string[]
}>()

const classes = computed(() => {
  if (!page.value.rarity) return [props.h1class]
  return [props.h1class, 'rarity', rarityClass(page.value.rarity)]
})
</script>

<template>
  <header>
    <BreadCrumbs />
    <div v-if="!page.hidetitle" class="title">
      <h1 :class="classes">{{ page.title }}</h1>
      <div class="title-icons noprint hidden-mobile">
        <a v-if="page.dndbeyond" :href="page.dndbeyond" target="_blank">
          <img class="dndbeyond" src="/img/dnd-beyond-logo.svg" alt="DnD Beyond" />
        </a>
        <Icon v-if="page.public" name="fa6-solid:eye" class="dm-only-icon size-2x" title="Public page" />
        <Icon v-else name="fa6-solid:eye-slash" class="dm-only-icon size-2x" title="DM-only page" />
        <Icon
          v-if="page.outdated"
          name="fa6-solid:ban"
          class="outdated-icon size-2x"
          title="Outdated content for 5e14"
        />
        <Icon
          v-if="page.updated"
          name="game-icons:upgrade"
          class="upgraded-icon size-2x"
          title="Updated 5e14 WotC content"
        />
      </div>
    </div>
  </header>
</template>

<style scoped lang="postcss">
header {
  display: flex;
  flex-direction: column;
}

.title {
  border-bottom: 2px solid var(--primary);
  display: flex;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 0.5rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  h1 {
    display: flex;
    margin: 0;
    text-align: center;
    align-items: center;
    font-size: 2rem;

    &::before,
    &::after {
      content: ' ';
      background-image: var(--triquetra);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 1.1em;
      width: 1.1em;
      margin-right: 0.5em;
      margin-left: 0.5em;
      print-color-adjust: exact !important;
    }

    &::before {
      transform: rotate(180deg);
    }

    @media screen and (max-width: 950px) {
      flex: 1;
      font-size: 1.5rem;
      justify-content: center;
      text-align: center;
    }
  }

  .title-icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;

    > .icon {
      width: 3rem;
      text-align: center;
      color: var(--text-highlight);
    }
  }

  .dndbeyond {
    width: 100px;
    margin: 10px;
    filter: drop-shadow(0 0 2px black);
  }
}

.notdm .title-icons > .dm-only-icon {
  display: none;
}

@media print {
  .title {
    justify-content: center;
  }
}
</style>
