<script setup lang="ts">
import Tabletop from '../components/tabletop/Tabletop.vue'
import GlobalDiceRoller from '~/components/dice/GlobalDiceRoller.vue'

const { page } = useContent()
useContentHead(page)
</script>

<template>
  <main class="darkmode">
    <ClientOnly>
      <Tabletop />
    </ClientOnly>
    <GlobalDiceRoller />
  </main>
</template>

<style scoped lang="postcss">
main {
  padding: 0;
}
</style>
