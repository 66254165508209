<template>
  <form v-if="editCreature" class="editform" @submit.prevent="">
    <h3>Conditions</h3>
    <div class="conditions">
      <label v-for="cond in abilities" :key="cond">
        <input v-model="editCreature.conditions" type="checkbox" :value="cond" @change="updateConditions" />
        {{ cond }}
      </label>
    </div>
    <div class="conditions">
      <label v-for="cond in conditions" :key="cond">
        <input v-model="editCreature.conditions" type="checkbox" :value="cond" @change="updateConditions" />
        {{ cond }}
      </label>
    </div>
    <div class="conditions">
      <label>
        <input v-model="editCreature.conditions" type="checkbox" value="concentration" @change="updateConditions" />
        concentration
      </label>
      <label for="auraRange"> Emanation ({{ editCreature.aura * 5 }}ft) </label>
      <input id="auraRange" v-model="editCreature.aura" type="range" min="0" max="6" @change="updateAura" />
    </div>
  </form>
</template>

<script setup lang="ts">
const props = defineProps<{
  editing: string | null
}>()

const { table, sendCommand } = useTabletop()

const editCreature = computed(() => {
  if (!props.editing) return null
  return Object.assign({}, table.value.characters[props.editing])
})

const abilities = [
  'bless',
  'bane',
  'buff',
  'debuff',
  'marked',
  'booming',
  'cursed',
  'hex',
  'polymorph',
  'mind whip',
  'flying',
  'climb',
  'haste',
  'slow',
  'inspired',
  'burning',
  'advantage',
  'disadvantage',
]
const conditions = [
  'stunned',
  'paralyzed',
  'poisoned',
  'prone',
  'frightened',
  'unconscious',
  'charmed',
  'restrained',
  'invisible',
  'deafened',
  'blinded',
  'grappled',
  'petrified',
  'dead',
  'incapacitated',
  'bloodied',
]

const updateConditions = () =>
  sendCommand('SET_CONDITION', { id: editCreature.value?.id, conditions: editCreature.value?.conditions })

const updateAura = () => sendCommand('SET_AURA', { id: editCreature.value?.id, aura: editCreature.value?.aura })
</script>

<style scoped lang="postcss">
.conditions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5em;

  border-top: 2px solid var(--primary);
  padding-top: 0.5em;

  label {
    display: flex;
    margin-bottom: 0;
    text-transform: capitalize;
    min-width: 34%;
    /* 34% ensures there can't be three elements on the same row */

    > input {
      margin-right: 5px;
    }

    &:nth-child(2n) {
      text-align: right;
      flex-direction: row-reverse;

      > input {
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }
}
</style>
