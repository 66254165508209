<template>
  <g class="aoe" :style="style">
    <polygon :points="points" />
    <line class="vector" :x1="origin.x" :y1="origin.y" :x2="target.x" :y2="target.y" />
    <circle
      class="origin"
      :cx="origin.x"
      :cy="origin.y"
      r="10"
      draggable="true"
      @mousedown="dragOrigin"
      @click.right.prevent="remove"
    />
    <circle
      class="target"
      :cx="target.x"
      :cy="target.y"
      r="10"
      draggable="true"
      @mousedown="dragTarget"
      @click.right.prevent="remove"
    />
    <text v-if="text" :x="target.x" :y="target.y - 15" text-anchor="middle">{{ text }}</text>
  </g>
</template>
<script setup lang="ts">
import type { CSSProperties } from 'vue'
import {
  createCone,
  createLine,
  createRectangle,
  createSphere,
  GRID_SIZE,
  gridDistance,
  toPoints,
  type AreaData,
  type Position,
} from './tabletopUtil'

const props = defineProps<{
  area: AreaData
}>()

const offset = computed(() => {
  if (props.area.type === 'cone') return 0.5
  if (props.area.type === 'cube') return 0.5
  if (props.area.type === 'line') return 0.5
  return 0
})

const origin = computed(() => {
  const origin = tempOrigin.value || props.area.origin
  return {
    x: (origin.x + offset.value) * GRID_SIZE,
    y: (origin.y + offset.value) * GRID_SIZE,
  }
})

const target = computed(() => {
  const target = tempTarget.value || props.area.target
  return {
    x: (target.x + offset.value) * GRID_SIZE,
    y: (target.y + offset.value) * GRID_SIZE,
  }
})

const tempOrigin = ref<Position | null>(null)
const tempTarget = ref<Position | null>(null)

const polygon = computed(() => {
  const origin = tempOrigin.value || props.area.origin
  const target = tempTarget.value || props.area.target

  if (props.area.type === 'sphere') return createSphere(origin, target)
  if (props.area.type === 'cube') return createRectangle(origin, target)
  if (props.area.type === 'cone') return createCone(origin, target)
  if (props.area.type === 'line') return createLine(origin, target)

  return createRectangle(origin, target)
})
const points = computed(() => toPoints(polygon.value))

const { sendCommand, onDrop, table } = useTabletop()

const remove = () => {
  sendCommand('REMOVE_AOE', { id: props.area.id })
}

const toGridCoords = (p: Position): Position => ({ x: p.x - 1, y: p.y - 1 })

const dragOrigin = () =>
  onDrop({
    end: (pos) => {
      sendCommand('MOVE_AOE', { id: props.area.id, origin: toGridCoords(pos), target: tempTarget.value })
      tempOrigin.value = null
      tempTarget.value = null
    },
    progress: (pos) => {
      tempOrigin.value = toGridCoords(pos)
      const deltaX = tempOrigin.value.x - props.area.origin.x
      const deltaY = tempOrigin.value.y - props.area.origin.y
      tempTarget.value = {
        x: props.area.target.x + deltaX,
        y: props.area.target.y + deltaY,
      }
    },
  })

const dragTarget = () =>
  onDrop({
    end: (pos) => {
      sendCommand('MOVE_AOE', { id: props.area.id, target: toGridCoords(pos) })
      tempTarget.value = null
    },
    progress: (pos) => {
      tempTarget.value = toGridCoords(pos)
    },
  })

const style = computed<CSSProperties>(() => {
  const color = table.value.players[props.area.owner!]?.color ?? '#aaa'
  return {
    color,
  }
})

const text = computed(() => {
  const origin = tempOrigin.value || props.area.origin
  const target = tempTarget.value || props.area.target
  if (props.area.type === 'sphere') {
    const r = gridDistance([origin.x, origin.y], [target.x, target.y])
    return `${r * 5}ft`
  } else if (props.area.type === 'cone' || props.area.type === 'line') {
    const r = gridDistance([origin.x, origin.y], [target.x, target.y]) + 1
    return `${r * 5}ft`
  } else if (props.area.type === 'cube') {
    const dX = (Math.abs(origin.x - target.x) + 1) * 5
    const dY = (Math.abs(origin.y - target.y) + 1) * 5
    return `${dX}x${dY}`
  }

  return ''
})
</script>

<style scoped lang="postcss">
g.aoe {
  polygon {
    fill: none;
    stroke: currentColor;
    stroke-width: 4px;
  }

  .origin,
  .target {
    fill: var(--text);
    pointer-events: all;
    cursor: move;
    opacity: 0.8;
  }

  .vector {
    stroke: var(--text);
    stroke-width: 4px;
    opacity: 0.8;
  }

  text {
    fill: var(--text-highlight);
    font-family: var(--font-stack-title);
    margin: 0.25rem;
  }
}
</style>
