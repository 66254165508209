<template>
  <div class="tabletop" :class="{ dm: dungeonMaster }">
    <div class="playareawrapper">
      <div
        ref="playarea"
        class="playarea"
        :style="playareaStyle"
        @drop="dropHandler"
        @dragover.prevent=""
        @mousedown="pointStart($event)"
        @mousemove="dragMove($event)"
        @mouseup="dropHandler"
      >
        <transition-group tag="div" class="creatures" name="grid">
          <Creature
            v-for="char in table.characters"
            :key="char.id"
            :char="char"
            :dungeon-master="dungeonMaster"
            :class="{ editing: editing === char.id }"
            @dragstart="onCreatureDrag($event, char.id, char.friendly)"
            @click="onCreatureClick($event, char.id)"
          />
        </transition-group>

        <Pointer v-if="tempPointer" :pointer="tempPointer" :dimensions="table.map.dimensions" />
        <Pointer
          v-for="pointer in playerPointers"
          :key="pointer.x + '/' + pointer.y + '/' + pointer.color"
          :pointer="pointer"
          :dimensions="table.map.dimensions"
        />
        <SVGAreas :dimensions="table.map.dimensions" :areas="table.map.aoes || {}" :temp-area="tempArea" />
        <FogOfWar v-for="(fog, id) in table.map.fog" :key="id" :fog="fog" :dm="dungeonMaster" />
        <TabletopOverlay
          v-if="table.map.dimensions"
          :width="table.map.dimensions.x"
          :height="table.map.dimensions.y"
          :target="editing"
        />
      </div>
    </div>
    <div class="dmtools">
      <ul class="players fa-ul">
        <li v-for="player in table.players" :key="player.id" class="player" :style="{ color: player.color }">
          <Icon :name="player.dm ? 'game-icons:laurel-crown' : 'fa6-solid:circle'" />
          {{ player.name }}
        </li>
      </ul>
      <form v-if="playerId" class="playerform" @submit.prevent="updatePlayer">
        <h3>Player Info</h3>
        <input v-model="playerData.name" type="text" />
        <input v-model="playerData.color" type="color" />
        <div class="broadcast container horizontal">
          <input id="broadcast-input" v-model="playerData.broadcastDice" type="checkbox" />
          <label for="broadcast-input">Broadcast Rolls</label>
        </div>
        <button>Update</button>
      </form>
      <InitiativeTabletop :dungeon-master="dungeonMaster" />
      <div class="spacer"></div>
      <form class="aoe-options">
        <span>Alt-drag to mark AoE:</span>
        <div class="container horizontal">
          <label v-for="type in AREA_TYPES" :key="type">
            <Icon :name="type" />
            <input v-model="areaType" type="radio" :value="type" />
          </label>
        </div>
      </form>
      <NuxtLink to="/" class="homelink">
        <h3><Icon name="fa6-solid:chevron-left" />&nbsp;Index</h3>
      </NuxtLink>
    </div>
    <div v-if="dungeonMaster" class="dmtools">
      <ConditionForm :editing="editing" />

      <div class="spacer" style="flex: 1"></div>

      <form class="changemapform" @submit.prevent="">
        <h3>Change map</h3>
        <select aria-label="Map" @change="changeMap($event)">
          <option :value="null">---</option>
          <option v-for="map in mapOptions" :key="map" :value="map">{{ map }}</option>
        </select>
      </form>

      <form class="effectsform" @submit.prevent="">
        <h3>Effects</h3>
        <div class="fx-pickers">
          <div
            v-for="(color, name) in fxOptions"
            :key="name"
            class="fx"
            :class="{ active: fxType === name }"
            :style="{ background: color }"
            @click="fxType = name"
          ></div>
        </div>
      </form>

      <form class="newcreatureform" @submit.prevent="">
        <h3>New Character</h3>
        <input v-model="newCreature.name" type="text" />
        <input v-model.number="newCreature.size" type="range" min="0" max="4" />
        <label
          >{{ newCreature.initMod }}&nbsp;<input v-model.number="newCreature.initMod" type="range" min="-5" max="5"
        /></label>
        <label><input v-model="newCreature.friendly" type="checkbox" />&nbsp;Friendly?</label>
        <div class="bgs">
          <div
            v-for="(defaultName, bg) in bgOptions"
            :key="bg"
            class="bg"
            :style="{ background: bg }"
            @click="setBackground(bg, defaultName)"
          ></div>
        </div>
      </form>

      <div class="newcreature">
        <Creature :char="newCreature" :dungeon-master="dungeonMaster" @dragstart="onNewCreatureDragStart" />
      </div>

      <div class="killzone" @dragover.prevent="" @dblclick="dumpEncounter"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import _ from 'lodash'
import { d20 } from '../../assets/dice/diceRoll'
import { clamp, copyToClipboard } from '../util'
import ConditionForm from './ConditionForm.vue'
import Creature from './Creature.vue'
import InitiativeTabletop from './InitiativeTabletop.vue'
import TabletopOverlay from './TabletopOverlay.vue'
import FogOfWar from './FogOfWar.vue'
import Pointer, { type PointerData } from './Pointer.vue'
import {
  GRID_SIZE,
  type Position,
  type CharData,
  createArea,
  type TabletopData,
  type AreaData,
  type AreaType,
  AREA_TYPES,
  type DropHandler,
  computePosition,
} from './tabletopUtil'
import SVGAreas from './SVGAreas.vue'
import { fxOptions, bgOptions, maps } from '~/assets/tabletopData'

const ws = ref<WebSocket | null>(null)
const sendCommand = (type: string, data?: any) => {
  if (ws.value && ws.value.readyState === 1) {
    const cmd = JSON.stringify({ type, data })
    console.log('>>>', cmd)
    ws.value.send(cmd)
  }
}
provide('sendCommand', sendCommand)

const dungeonMaster = useDM()

const store = useStore()
const table = computed<TabletopData>(() => store.table)
const playarea = ref<HTMLElement | null>(null)

const playerData = ref(store.playerData || { name: 'Player', color: '#e27e29', broadcastDice: false })
const updatePlayer = () => sendCommand('EDIT_PLAYER', playerData.value)

const editing = ref<string | null>(null)
const shiftDown = ref(false)
const controlDown = ref(false)
const altDown = ref(false)

onMounted(() => {
  const connect = () => {
    ws.value = new WebSocket(`wss://estia-server-m6x2fqeyga-ew.a.run.app/connect`)
    ws.value.onmessage = (msg) => {
      if (msg.data === 'pong') return
      const cmd = JSON.parse(msg.data)
      if (cmd.state) {
        // console.log('<<<', JSON.stringify(cmd.state, null, 2))
        store.table = cmd.state
      } else if (cmd.dice) {
        const event = new CustomEvent('diceResult', { detail: { dice: cmd.dice } })
        document.body.dispatchEvent(event)
      } else if (cmd.id) {
        playerId.value = cmd.id
      }
    }
    ws.value.onopen = () => sendCommand('EDIT_PLAYER', { ...playerData.value, dm: dungeonMaster.value })
    ws.value.onclose = () => {
      // this should ideally do some sort of exponential backoff
      console.log('Reconnecting...')
      connect()
    }
  }

  connect()

  document.onkeydown = (ev) => {
    if (ev.key === 'Escape') {
      editing.value = null
    } else if (ev.key === 'Shift') {
      shiftDown.value = true
    } else if (ev.key === 'Control' || ev.key === 'Meta') {
      controlDown.value = true
    } else if (ev.key === 'Alt') {
      altDown.value = true
    }
  }

  document.onkeyup = (ev) => {
    if (ev.key === 'Shift') {
      shiftDown.value = false
    } else if (ev.key === 'Control' || ev.key === 'Meta') {
      controlDown.value = false
    } else if (ev.key === 'Alt') {
      altDown.value = false
    }
  }

  setInterval(() => {
    ws.value?.send('ping') // keepalive for the websocket connection
  }, 29000)

  setInterval(() => {
    fetch('https://estia-server-m6x2fqeyga-ew.a.run.app/') // keepalive for the server
  }, 180000)
})

onUnmounted(() => {
  if (!ws.value) return
  console.log('Disconnecting...')
  ws.value.onclose = null
  ws.value.close()
})

const playerId = ref(null)

const playareaStyle = computed(() => {
  const map = table.value.map
  if (!map.dimensions) return {}
  return {
    'background-image': map.sprite,
    width: map.dimensions.x * GRID_SIZE + 'px',
    height: map.dimensions.y * GRID_SIZE + 'px',
    'grid-template-columns': `repeat(${map.dimensions.x}, var(--grid-size))`,
    'grid-template-rows': `repeat(${map.dimensions.y}, var(--grid-size))`,
    ...(map.style || {}),
  }
})

const playerPointers = computed<PointerData[]>(() => {
  const players = Object.values(table.value.players || {})
  return players.flatMap((p) => (p.pointers || []).map((area) => ({ color: p.color, ...area })))
})

const newPoint = ref<Position | null>(null)
const tempPoint = ref<Position | null>(null)

const tempPointer = computed<PointerData | null>(() => {
  if (altDown.value || !(newPoint.value && tempPoint.value)) return null

  const tempPointer = createArea(newPoint.value, tempPoint.value) as PointerData
  tempPointer.color = store.playerData.color

  return tempPointer
})

const createAoe = (origin: Position, target: Position, type: AreaType = 'sphere'): AreaData => {
  return {
    origin: {
      x: origin.x - 1, // these are 1-indexed :((
      y: origin.y - 1,
    },
    target: {
      x: target.x - 1,
      y: target.y - 1,
    },
    type,
  }
}

const areaType = ref<AreaType>('sphere')
const tempArea = computed<AreaData | null>(() => {
  if (!altDown.value || !(newPoint.value && tempPoint.value)) return null
  const res = createAoe(newPoint.value, tempPoint.value, areaType.value)
  res.id = `${newPoint.value.x}${newPoint.value.y}${tempPoint.value.x}${tempPoint.value.y}`
  res.owner = playerId.value ?? undefined
  return res
})

const fxType = ref<string | null>(null)
const pointStart = (ev: MouseEvent) => {
  if (ev.button !== 0) return
  const target = ev.target as HTMLElement | null
  if (!target?.classList.contains('overlay')) return
  // if (ev.target !== this.$refs.playarea) return
  const pos = getPosition(ev)
  newPoint.value = pos

  // split into different calls?
  onDrop({
    end: (pos) => {
      if (newPoint.value && playerId.value) {
        const area = createArea(newPoint.value, pos)

        const myPointers = table.value.players[playerId.value]?.pointers
        const isClickingSamePoint = myPointers.length === 1 && _.isEqual(myPointers[0], area)
        if (!shiftDown.value) sendCommand('CLEAR_POINTERS')

        if (controlDown.value && dungeonMaster.value && fxType.value) {
          const effect = { ...area, type: fxType.value }
          sendCommand('ADD_FOG', effect)
        } else if (altDown.value && !_.isEqual(newPoint.value, pos)) {
          sendCommand('ADD_AOE', createAoe(newPoint.value, pos, areaType.value))
        } else if (!isClickingSamePoint) {
          sendCommand('ADD_POINTER', area)
        }
        newPoint.value = null
        tempPoint.value = null
      }
    },
    progress: (pos) => {
      if (!newPoint.value) return
      if (_.isEqual(newPoint.value, pos)) return
      tempPoint.value = pos
    },
  })
}

const getPosition = (ev: MouseEvent): Position => {
  let target = ev.target as HTMLElement | null

  let x = ev.layerX
  let y = ev.layerY

  while (target && target !== playarea.value) {
    if (dropFns.value?.dropClass && target?.classList.contains(dropFns.value.dropClass)) break
    // needed if the map does not fill the vtt playarea
    x += target.offsetLeft ?? 0
    y += target.offsetTop ?? 0
    target = target.parentElement
  }

  if (target === playarea.value) {
    x -= playarea.value!.offsetLeft
    y -= playarea.value!.offsetTop
  }

  x = Math.ceil(x / GRID_SIZE)
  y = Math.ceil(y / GRID_SIZE)
  return { x, y }
}

const mapOptions = Object.keys(maps).sort()
const mapName = ref('')

const changeMap = (event: Event) => {
  const target = event.target as HTMLSelectElement
  const map = maps[target.value]
  if (!map) return
  mapName.value = target.value
  console.log('changing map:', debug(map, false))
  fetch('https://estia-server-m6x2fqeyga-ew.a.run.app/setencounter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ map }),
  })
}

const dumpEncounter = () => {
  const res: any = {}
  res.map = mapName.value
  res.characters = Object.values(table.value.characters).map((char) => {
    const result = {
      name: char.name,
      sprite: char.sprite,
      size: char.size,
      friendly: char.friendly,
      initMod: char.initMod,
      position: {
        x: char.position.x,
        y: char.position.y,
      },
    }
    if (char.mountedOn) {
      const mount = table.value.characters[char.mountedOn]
      result.position.x += mount.position.x - 1
      result.position.y += mount.position.y - 1
    }
    return result
  })

  res.fog = Object.values(table.value.map.fog).map((fog) => fog.rect)
  // output can be placed in an encounter file
  const data = JSON.stringify(res)
  console.log(data)
  copyToClipboard(data)
}

const dropFns = ref<DropHandler | null>()

const onDrop = (handler: DropHandler) => {
  dropFns.value = handler
}

provide('onDrop', onDrop)

const findValidDropTarget = (el: HTMLElement): HTMLElement => {
  if (el === playarea.value || (dropFns.value?.dropClass && el?.classList.contains(dropFns.value.dropClass))) return el
  if (el.parentElement === null) return playarea.value!
  return findValidDropTarget(el.parentElement)
}

const dropHandler = (ev: MouseEvent) => {
  const pos = getPosition(ev)
  const target = findValidDropTarget(ev.target as HTMLElement)
  if (dropFns.value?.end) {
    dropFns.value.end(pos, target)
    dropFns.value = null
  }
}

const dragMove = (ev: MouseEvent) => {
  const pos = getPosition(ev)
  const target = findValidDropTarget(ev.target as HTMLElement)
  if (dropFns.value?.progress && !_.isEqual(dropFns.value?.lastProgress, pos)) {
    dropFns.value?.progress(pos, target)
    dropFns.value.lastProgress = pos
  }
}

const onCreatureDrag = (_ev: Event, id: string, friendly: boolean) => {
  if (!friendly && !dungeonMaster.value) return
  onDrop({
    end: (position, target) => {
      let mountedOn = null
      if (target.classList.contains('character')) {
        mountedOn = target.getAttribute('data-id')
        const c2 = table.value.characters[mountedOn ?? '']
        if (c2?.mountedOn || mountedOn === id) {
          // cant mount self or creatures who are themselves mounted
          // in both cases, target the square underneath
          mountedOn = null
          const [x, y] = computePosition(table.value, c2)
          position.x += x - 1
          position.y += y - 1
        }
      }
      sendCommand('MOVE_CHARACTER', { id, position, mountedOn })
    },
    dropClass: 'character',
  })
}

const newCreature = ref<CharData>({
  name: 'New Creature',
  position: { x: 1, y: 1 },
  size: 1,
  id: '',
  aura: 0,
  conditions: [],
  sprite: '#6495ed',
  friendly: false,
  initMod: 0,
  mountedOn: null,
})

const onNewCreatureDragStart = () => {
  if (!dungeonMaster.value) return

  onDrop({
    end: (pos, _target) => {
      const initiative = clamp(d20() + (newCreature.value.initMod || 0), 1, 25)
      sendCommand('ADD_CHARACTER', { ...newCreature.value, initiative, position: pos })
    },
  })
}

const setBackground = (bg: string, name: string) => {
  newCreature.value.sprite = bg
  newCreature.value.name = name
  newCreature.value.size = 1
}

const onCreatureClick = (ev: MouseEvent, id: string) => {
  ev.stopPropagation()
  if (dungeonMaster.value && shiftDown.value) {
    sendCommand('REMOVE_CHARACTER', { id })
    if (id === editing.value) editing.value = null
  } else {
    editing.value = id === editing.value ? null : id
  }
}
</script>

<style lang="postcss">
.tabletop {
  .dmtools {
    h3 {
      text-align: center;
      margin-top: 1rem;
    }
  }
}
</style>

<style scoped lang="postcss">
.tabletop {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
}

.dmtools {
  width: 230px;
  border-left: 2px solid var(--primary);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .spacer {
    flex: 1;
  }

  .homelink {
    padding-bottom: 16px;

    h3 {
      color: var(--primary);
    }
  }
}

.playareawrapper {
  background: black;
  flex: 1;
  overflow: auto;
  position: relative;
  display: flex;
}

.playarea {
  background: url(/maps/default.png);
  width: 1800px;
  height: 1600px;
  margin: auto;
  display: grid;
  background-size: cover;
  align-self: center;
  grid-template-columns: repeat(auto-fill, var(--grid-size));
  grid-template-rows: repeat(auto-fill, var(--grid-size));

  .creatures {
    display: contents;

    .grid-leave-to {
      opacity: 0;
      transform: scale(0);
    }

    .grid-enter {
      opacity: 0;
      transform: scale(3);
    }
  }
}

.players {
  margin-bottom: 0;
  list-style: none;
  padding: 0 1em;
  --fa-primary-color: currentColor;

  .player {
    font-size: 1.2em;
    font-family: var(--font-stack-title);
    text-shadow: 0 0 1px black, 0 0 2px black;
  }
}

form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;

  label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    > input {
      margin-bottom: 0;
    }
  }

  input {
    margin-bottom: 5px;
    width: 100%;

    &[type='checkbox'] {
      width: auto;
      margin-right: 0.5em;
      margin-bottom: 3px;
    }
  }

  label > input[type='radio'] {
    appearance: none;
    width: 0;
    height: 0;
  }
}

.aoe-options {
  display: flex;
  align-items: center;
  gap: 0.5em;

  .container {
    gap: 0.25em;
  }

  label {
    border: 2px solid transparent;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0.25em;
  }

  label:has(> input:checked) {
    color: var(--text-highlight);
    border-color: var(--primary);
  }

  .icon {
    height: 24px;
    width: 24px;
  }
}

.fx-pickers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .fx {
    width: calc(var(--grid-size) / 2);
    height: calc(var(--grid-size) / 2);
    background-size: cover !important;
    border: 1px solid black;
    border-radius: 50%;
    margin-left: 1px;
    margin-bottom: 1px;

    &.active {
      box-shadow: 0 0 5px var(--primary);
    }
  }
}

.newcreature {
  padding: 10px;
  margin: 10px;
  align-self: center;
  justify-content: center;
  align-content: center;
  width: 100px;
  height: 100px;
  display: grid;
  grid-template-columns: repeat(1, var(--grid-size));
  grid-template-rows: repeat(1, var(--grid-size));
}

.bgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .bg {
    width: calc(var(--grid-size) / 2);
    height: calc(var(--grid-size) / 2);
    background-size: cover !important;
    border: 1px solid black;
    border-radius: 50%;
    margin-left: 1px;
    margin-bottom: 1px;
  }
}

.killzone {
  flex: 0 0 25px;
  border: 2px solid red;
}
</style>
